
export  function promiseReducer(state = {}, { type, name, status, payload, error }) {
    if (type === "PROMISE") {
        return {
            ...state,
            [name]: { status, payload, error },
        };
    }
    return state;
  }
 
 export const getData = url => 
  fetch(url, {
    method: "GET",
    headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : "*"
    },// Set the mode to "cors"
  })
  .then(res => res.json())


  export const pushData = (url, object) => 
  fetch(url , {
    method: "POST",
     headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : "*"
    },
    body: JSON.stringify(object)
  })
    .then(res => res.json())
    .catch(error => {
      console.error(error);
      throw new Error('Unable to push data.');
    });

// export const getData = url => 
//         fetch(url , {
//             method: "GET",
//         })
//         .then(res => res.json())

        
// export const pushData = url => 
//     fetch(url , {
//         method: "POST",
//     })


      

