// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Monoton&family=Raleway:wght@100;200;400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body::-webkit-scrollbar {
  display: none;
}

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Raleway', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,aAAa;AACf;;AAEA;EACE,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Monoton&family=Raleway:wght@100;200;400;600;700&display=swap');\n\nbody::-webkit-scrollbar {\n  display: none;\n}\n\n* {\n  margin: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  font-family: 'Raleway', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
