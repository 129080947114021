import React, { useState } from "react";
import { useEffect } from "react";
import {promiseReducer , getData, pushData } from "../store/promiseReducer"
import { store } from "../store/store"
import { connect } from "react-redux";




// ! PROMISE
const actionPending = (name) => ({
    type: "PROMISE",
    status: "PENDING",
    name,
  });

const actionFulfilled = (name, payload) => ({
    type: "PROMISE",
    status: "FULFILLED",
    name,
    payload,
  });

const actionRejected = (name, error) => ({
    type: "PROMISE",
    status: "REJECTED",
    name,
    error,
  });

export  const actionPromise = (name, promise) => async (dispatch) => {
    try {
        dispatch(actionPending(name));
        let payload = await promise;
        dispatch(actionFulfilled(name, payload));
        return payload;
    } catch (e) {
        dispatch(actionRejected(name, e));
    }
  };


// !GET PRODUCTS
export const actionGetSpots = () => 
        async dispatch => {
          await dispatch(actionPromise("spots" , getData("https://www.mythai.ua/spots")))
        }

export const actionFullGetCategories = () =>
        async dispatch => {
            await dispatch(actionPromise("categories" , getData("https://www.mythai.ua/categories")))
        }

export const actionFullGetProducts = () =>
        async dispatch => {
            await dispatch(actionPromise("products" , getData("https://www.mythai.ua/products")))
        }

export const actionFullGetProductsFranchise = () =>
        async dispatch => {
            await dispatch(actionPromise("productsFranchise" , getData("https://www.mythai.ua/productsLimeTest")))
        }
        
export const actionFullGetProductsStaroconst = () =>
        async dispatch => {
            await dispatch(actionPromise("productsStaroconst" , getData("https://www.mythai.ua/productsStaroconst")))
        }
        // productsMyThaiDnepr
        
export const actionFullGetProductsMyThaiDnepr = () =>
        async dispatch => {
            await dispatch(actionPromise("productsMyThaiDnepr" , getData("https://www.mythai.ua/productsMyThaiDnepr")))
        }

export const actionFullGetIngredients = () =>
        async dispatch => {
            await dispatch(actionPromise("ingredients" , getData("https://www.mythai.ua/getIngredients")))
        }

export const actionRequestOrder = () => 
        async dispatch => {
            await dispatch(actionPromise("order" , pushData("https://www.mythai.ua/buy")))
        }

export const actionGetClients = () => 
        async dispatch => {
            await dispatch(actionPromise("clients" , getData("https://www.mythai.ua/clients")))
        }

export const SET_SEARCH_INPUT_VALUE = "SET_SEARCH_INPUT_VALUE";


export const setSearchInput = (searchInputValue) => ({
          type: SET_SEARCH_INPUT_VALUE,
          payload: searchInputValue,
        });

export const setFranchise = () => 
        async dispatch => {
          await dispatch(actionPromise("franchise" , getData("https://www.mythai.ua/franchise")))
        }

export const actionAddClient = (object) => 
    async dispatch => {
            try {
              await dispatch(actionPromise("user" , pushData("https://www.mythai.ua/addClient", object)))
            } catch (error) {
              console.log(error.message)
            }
    }

export const actionBuy = (object) => 
      async dispatch => {
          try{
            await dispatch(actionPromise('submitOrder' , pushData("https://www.mythai.ua/buy" , object)))
          } catch (error) {
            console.log(error.message)
          }
      }

export const actionDelivery = () => 
        async dispatch => {
           await dispatch(actionPromise("delivery" , getData("https://www.mythai.ua/prices")))
        }















