import './App.css';
import React, { createContext, useState } from 'react';
import { Provider , connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes
} from "react-router-dom";
import { store } from './store/store';
import "./App.css"

import { MainPage } from './pages/MainPage';
import { useEffect ,CSSProperties } from 'react';
import { actionFullGetCategories, actionFullGetIngredients, actionFullGetProducts, actionFullGetProductsFranchise, actionGetClients, actionGetSpots, setFranchise , actionFullGetProductsStaroconst , actionDelivery , actionFullGetProductsMyThaiDnepr} from './actions/Actions';
import SubmitOrder, { SubmitOrderConnect } from './pages/SubmitOrder';
import { BasketConnect } from './components/Basket';
import { actionSpotSelect } from './store/cardReducer';
import BeatLoader from 'react-spinners/BeatLoader';
import Payment from './pages/Payment';
import Contacts from './pages/Contacts';
import Discount from './pages/Discount';
import RefundPolicy from "./pages/RefundPolicy"

import { loadFirePreset } from 'tsparticles-preset-fire';
import {RestaurantsConnect} from './pages/Restaurants';
import Particles from 'react-tsparticles';
import Lottie from 'lottie-web-react';
import watchAnimationData from './images/json1.json';

import {BasketMobileConnect} from "./components/BasketMobile"
import DeclinedOrder from './pages/DeclinedOrder';

// spots -> get_categories -> product get_spots = id 
// если выбран спот 1 то тогда делаем диспатч в редакс и передаем стоп , после этого на компоненте делаем проверку в зависимости от спота рендерим компонент продак спот ид = 1 

store.subscribe(() => console.log(store.getState()));

const  renderer ='canvas'
const  rendererSettings = {
preserveAspectRatio:  'xMinYMin slice',
}


export class ParticlesContainer extends React.PureComponent {
  async customInit(engine: Engine): Promise<void> {
    await loadFirePreset(engine);
  }

  render() {
    const options = {
      preset: "fire",
    };

    return <Particles options={options} init={this.customInit} />;
  }
}

//  ! DARK MODE
export const ThemeContext = createContext(null)

function App() {
  const [theme , setTheme] = useState("light")
  const [selectRest , setSelectRest] = useState(true)
  const toogleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"))
  }
  const [loading , setLoading] = useState(false)
  const [loading1 , setLoading1] = useState(false)
  
  const [count, setCount] = useState(1);
  const screenWidth = window.innerWidth;

  // Установите ширину контейнера анимации равной ширине экрана
  const containerStyle = {
    width: `${screenWidth}px`,
  };
  
  
    const [playingState, setPlayingState] = useState('play')
    const [autoplay, setAutoplay] = useState(false)
    const [loop, setLoop] = useState(true)
    const [path, setPath] = useState('https://www.mythai.ua/uploads/json.json')
    const [animationData, setAnimationData] = useState(watchAnimationData)
    const [speed, setSpeed] = useState(1.5)
    const [direction, setDirection] = useState(1)
    
    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            setLoading1(true);
    }, 4200); 
    
    return () => {
      clearTimeout(loadingTimeout);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 100) {
        setCount(count + 1);
      }
    }, 20);

    return () => clearInterval(interval);
  }, [count]);
  
   useEffect(() => {
    // Simulate loading with a timeout
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 5000); // Adjust the timeout duration as needed

    return () => {
      clearTimeout(loadingTimeout); // Clear the timeout on unmount
    };
  }, []);

  useEffect(() => {
    store.dispatch(actionGetSpots())
    store.dispatch(actionFullGetProductsFranchise())
    store.dispatch(actionGetClients())
    store.dispatch(actionFullGetCategories())
    store.dispatch(actionFullGetProducts())
    store.dispatch(actionFullGetIngredients())
    store.dispatch(actionFullGetProductsStaroconst())
    store.dispatch(actionFullGetProductsMyThaiDnepr())
    store.dispatch(setFranchise())
    store.dispatch(actionDelivery())
  }, [])
      

  useEffect(() => {
    let timeoutId;
  
    const checkSpotsStatus = async () => {
      try {
        const spotsStatus = store.getState().promise?.spots?.status;
        
        if (spotsStatus === "FULFILLED") {
          const selectedSpotId = localStorage.restaurant
          const selectedSpotAddress = localStorage.adress
          store.dispatch(actionSpotSelect(selectedSpotId ,selectedSpotAddress));
          setLoading(false);
        } else {
          setLoading(true);
          timeoutId = setTimeout(checkSpotsStatus, 4000);
        }
      } catch (error) {
        console.error(error);
        setLoading(true);
        timeoutId = setTimeout(checkSpotsStatus, 4000);
      }
    };
  
    checkSpotsStatus();
  
    return () => {
      clearTimeout(timeoutId);
    };
  }, [loading]);
 

  return (
      <>
          
                  {
                            loading ? 
                        <div className='cont_svg'>
                              <Lottie
                                    className="svg"
                                    options={{
                                    renderer:  renderer,
                                    loop:  false,
                                    autoplay:  autoplay,
                                    // path: path,
                                    animationData:  animationData,
                                    rendererSettings:  rendererSettings
                                    }}
                                    playingState={playingState}
                                    speed={speed}
                                    direction={direction}
                                    style={{width: "100%" , height: "100%"}}
                                    />
                              
                        </div>
                        :
      
    <div className={ !loading1 ? "Appnone" : 'App'} style={{position: 'relative'}}> 
          <style> @import url('https://fonts.cdnfonts.com/css/hitch-hike'); </style>
            <ParticlesContainer />
                        <div>
                              <Provider store={store}>
                                <Router>
                                      <Routes>
                                                <Route path="/" element={<MainPage />} exact/>
                                                <Route path='/orderSubmit' element={<SubmitOrderConnect />} exact/>
                                                <Route path='/orderDeclined' element={<DeclinedOrder />} exact/>
                                                <Route path="/basket" element={<BasketConnect/>} exact/>
                                                <Route path="/basketMobile" element={<BasketMobileConnect/>} exact/>
                                                <Route path='/payment'element={<Payment />} exact/>
                                                <Route path='/contacts'element={<Contacts />} exact/>
                                                <Route path='/discount'element={<Discount />} exact/>
                                                <Route path='/our_restaurants'element={<RestaurantsConnect />} exact/>
                                                <Route path='/refund'element={<RefundPolicy />} exact/>
                                      </Routes>
                                </Router>
                              </Provider>
                        </div>
      </div>
                 } 
      
      </>

  );
}

export default App;
