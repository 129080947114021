import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Link  , useNavigate} from 'react-router-dom'
import { store } from '../store/store'

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { useEffect } from 'react';
import { useState } from 'react';
import { actionAddClient, actionBuy } from '../actions/Actions';
import { actionError, actionNumberOfOrder } from '../store/cardReducer';

import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';


const SubmitOrder = ({order, error  , id_order , id_error }) => {
  const selectedSpot = useSelector((state) => state.cardReducer?.spot);
  const navigation = useNavigate()

  const [userInfo, setUserInfo] = useState(false)

  const [userCookies, setUserCookies] = useState({
    name: '',
    phone: '',
    address: '',
    service_mode: '',
    productId: '',
    selectedTime: '',
    deliveryPrice: '',
    comment: '',
    payment: {},
    price: ""
  });
  
  const handlePurchaseClick = () => {
    // Ваш код для обработки нажатия на кнопку покупки

    // Отправка события в Google Analytics
    window.gtag('event', 'purchase', {
      event_category: 'button_click',
      event_label: 'Purchase Button Clicked'
    });
  };

  // !!!! get cookies
  useEffect(() => {
    // Получение значения из куки по имени
    const userData = Cookies.get('userCookies');
    
    if (userData) {
      const prsData = JSON.parse(userData)
      setUserCookies(prsData)
    }

  }, []);

  
  function handlePhoneCall() {
     if(selectedSpot?.name === "2"){
      return window.location.href = 'tel:+380660558522'; // lozova
    }
    if(selectedSpot?.name === "5"){
      return window.location.href = 'tel:+380992308969' // uman 
    }
    if(selectedSpot?.name === "8"){
      return window.location.href = 'tel:+380683494940' // staroconst
    }
    if(selectedSpot?.name === "9"){ 
      return window.location.href = 'tel:+380978335767' // slavuta
    }
    if(selectedSpot?.name === "10"){ 
      return window.location.href = 'tel:+380966079393' // kalush
    }
    if(selectedSpot?.name === "11"){
      return window.location.href = 'tel:+380960355161' // drogo
    }
    if(selectedSpot?.name === "12"){
        return window.location.href = 'tel:+380983063935' // xmel
    }
    if(selectedSpot?.name === "13"){
        return window.location.href = "tel:+380983028995" // chern
    }
    if(selectedSpot?.name === "14"){
        return window.location.href = "tel:+380686757676" // if 
    }
  }

  // ! это должно работать уже после оплаты юзером
      useEffect(() => {
        if(userCookies){
          store.dispatch(actionAddClient({
            spot: selectedSpot?.name ,
            name: userCookies?.name ,
            phone: userCookies?.phone ,
            address: userCookies?.address
          }))
          setUserInfo(true)
        }
      }, [userCookies])
  
  
    function apiKey(userCookies) {
        if (userCookies?.payment === 1) {
            if (selectedSpot?.name === "14" || selectedSpot?.name === "13" || selectedSpot?.name === "12" || selectedSpot?.name === "10" || selectedSpot?.name === "5"  || selectedSpot?.name === "11") {
                return { type: "1", sum: userCookies?.price * 100, currency: "UAH" };
            }
        }
        return { type: "0", sum: 0, currency: "UAH" };
    }
    
    // прикол в самовывозе где-то считает за 70 хотя должно считать за 0 
    
    useEffect(() => {
        if (userInfo) {
            store.dispatch(actionBuy({
                spot: selectedSpot?.name,
                name: userCookies?.name,
                phone: userCookies?.phone,
                address: userCookies?.address,
                service_mode: userCookies?.service_mode,
                products: userCookies?.productId,
                delivery_time: userCookies?.selectedTime,
                delivery_price: userCookies?.deliveryPrice,
                comment: userCookies?.comment,
                payment: apiKey(userCookies)
            }));
            fbq('track', 'Purchase', {value: userCookies?.price, currency: 'UAH'});
        }
    }, [userInfo]);

      console.log(userCookies)
    
      useEffect(() => {
          if(id_order){
            store.dispatch(actionNumberOfOrder(id_order))
            setTimeout(() => Cookies.remove('userCookies'), 1000);
          }
          if(id_error){
            store.dispatch(actionError(id_error))
          }
      }, [id_order , id_error])
    
      const handleReload = () => {
          navigation("/");
          window.location.reload();
      }
  
  return (
    <>
      {
        error && !order ? 
        <div className='submit_order_content'>
            <div className='payment_body'>
                <div className='submit_order_box'>
                  <h1>Вибачте , виникла помилка при замовленні!</h1>
                  <span>номер помилки №{error}</span>
                  <p style={{color: "white"}}>Будь-ласка зателефонуйте та передайте номер оператору або спробуйте ще раз!</p>
                  <div className='icon_phone_trigger' onClick={handlePhoneCall}>
                        <CallOutlinedIcon />  
                  </div>
                  <button className='link_submit' onClick={() => handleReload()}>Повернутися на сайт</button>
                </div>
            </div>
        </div>
        :
        <div className='submit_order_content'>
            <div className='payment_body'>
                <div className='submit_order_box'>
                      <h1>Дякуємо за замовлення!</h1>
                      <span>Замовлення номер №{order}</span>
                      <p>Ми зателефонуємо Вам найближчим часом для уточнення деталей!</p>
                      <div className='icon_phone_trigger' onClick={handlePhoneCall}>
                            <CallOutlinedIcon />  
                      </div>
                      <button className='link_submit' onClick={() => handleReload()}>Повернутися на сайт</button>
                </div>
            </div>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-93SD7YGMDW"></script>
                <script>
                  {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-93SD7YGMDW');
                    gtag('event', 'page_checkout', {
                    value: 100,
                    currency: 'UAH'
                  });
                  `}
                </script>
            </Helmet>
            
               
        </div>
      }
    </>
  )
}

export const SubmitOrderConnect = connect(state => ({ 
  order: state.cardReducer?.order, 
  error: state.cardReducer?.error, 
  user: state.cardReducer?.user_name,
  id_error: state.promise?.submitOrder?.payload?.incomingOrderId?.error || 0,
  id_order: state.promise?.submitOrder?.payload?.incomingOrderId?.incoming_order_id || 0
}), {

})(SubmitOrder) 
