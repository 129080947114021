import React , {useRef} from 'react'
import {Link} from 'react-router-dom'
import '../css/mainpage.css'
// ВЫНЕСТИ КОМПОНЕНТ СРАЗУ 

import { Header, HeaderConnect } from '../components/Header'
import {DishesConnect} from '../components/DishesCategory';
import { BasketConnect } from '../components/Basket';
import { useState } from 'react';
import Particles from 'react-tsparticles';

import banner1 from "../images/banner1.jpg"
import banner2 from '../images/banner2.jpg'
import banner3 from "../images/banner3.jpg"

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

import { useEffect } from 'react';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';

import inst from "../images/instagram.svg"

import InstagramIcon from '@mui/icons-material/Instagram';

 const image = [banner2, banner1];


const delay = 3000;

function Slideshow() {
  const [index, setIndex] = React.useState(0);
  const [images, setImages] = React.useState([]);
  const selectedSpot = useSelector((state) => state.cardReducer?.spot);
  const timeoutRef = React.useRef(null);
  const delay = 3000; // Задержка между слайдами (в миллисекундах)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  
  
    useEffect(() => {
        if(selectedSpot?.name === "2" || selectedSpot?.name === "12" || selectedSpot?.name === "14" || selectedSpot?.name === "10" || selectedSpot?.name === "13"){
            axios
              .get('https://www.mythai.ua/get-all-image-banners-lozo')
              .then(response => {
                const allPaths = response.data;
                setImages(allPaths);
              })
              .catch(error => {
                console.log(error);
              });
        }else{
            axios
              .get('https://www.mythai.ua/get-all-image-banners')
              .then(response => {
                const allPaths = response.data;
                setImages(allPaths);
              })
              .catch(error => {
                console.log(error);
              });
        }
    }, []);

//   useEffect(() => {
//     axios
//       .get('https://www.mythai.ua/get-all-image-banners')
//       .then(response => {
//         const allPaths = response.data;
//         setImages(allPaths);
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   }, []);

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() =>
      setIndex(prevIndex =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index, images.length]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {images.map((imageUrl, index) => (
            <img
                className="slide"
                key={index}
                src={`https://www.mythai.ua${imageUrl.path}`}
                alt={`Slide ${index}`}
          />
        ))}
      </div>
    </div>
  );
}

// function Slideshow() {
//   const [index, setIndex] = React.useState(0);
//   const timeoutRef = React.useRef(null);

//   function resetTimeout() {
//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }
//   }
  
//   useEffect(() => {
//     axios
//       .get('https://www.mythai.ua/get-all-image-banners')
//       .then(response => {
//         const allPaths = response.data;

//         console.log(response.data)
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   }, []);
  

//   React.useEffect(() => {
//     resetTimeout();
//     timeoutRef.current = setTimeout(
//       () =>
//         setIndex((prevIndex) =>
//           prevIndex === image?.length - 1 ? 0 : prevIndex + 1
//         ),
//       delay
//     );

//     return () => {
//       resetTimeout();
//     };
//   }, [index]);

//   return (
//     <div className="slideshow">
//       <div
//         className="slideshowSlider"
//         style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
//       >
//         {image?.map((backgroundImage, index) => (
//           <img
//             className="slide"
//             key={index}
//             src={backgroundImage}
//           ></img>
//         ))}
//       </div>
//     </div>
//   );
// }


function Discount() {
  const [modalIsOpen, setModalIsOpen] = useState(false); // Начальное значение - false
  const [imagePaths, setImagePaths] = useState([]);
  const [mobileImagePaths, setMobileImagePaths] = useState([]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const isMobileScreen = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    axios
      .get('https://www.mythai.ua/get-all-image-paths')
      .then(response => {
        const allPaths = response.data;
        const filteredPaths = allPaths.filter(image => !image.path.includes('uploads/mobile'));
        const filteredPaths2 = filteredPaths.filter(image => !image.path.includes('uploads/banners'));
        setImagePaths(filteredPaths2);
        if(filteredPaths.length === 0){
            closeModal()
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  
  useEffect(() => {
    axios
      .get('https://www.mythai.ua/get-all-image-mobile')
      .then(response => {
        const allPaths = response.data;
        const filteredPaths2 = allPaths.filter(image => !image.path.includes('uploads/banners'));

        setMobileImagePaths(filteredPaths2);
        if(allPaths.length === 0){
           closeModal()
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (isMobileScreen) {
      openModal(); // Открываем модальное окно на мобильных устройствах
    }
  }, [isMobileScreen]);

  const pathsToDisplay = isMobileScreen ? mobileImagePaths : imagePaths;

  return (
    <div>
      {modalIsOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            {pathsToDisplay.map((image) => (
              <img
                key={image.name}
                src={`https://www.mythai.ua${image.path}`}
                alt={image.name}
              />
            ))}
            <div className="cls_btn">
              <CloseIcon
                onClick={closeModal}
                sx={{ color: 'white', fontSize: 40, backgroundColor: 'inherit' }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}


export const MainPage = () => {
  const selectedSpot = useSelector((state) => state.cardReducer?.spot);

  function handlePhoneCall() {
    if(selectedSpot?.name === "2"){
      return window.location.href = 'tel:+380660558522'; // lozova
    }
    if(selectedSpot?.name === "5"){
      return window.location.href = 'tel:+380992308969' // uman 
    }
    if(selectedSpot?.name === "8"){
      return window.location.href = 'tel:+380683494940' // staroconst
    }
    if(selectedSpot?.name === "9"){ 
      return window.location.href = 'tel:+380978335767' // slavuta
    }
    if(selectedSpot?.name === "10"){ 
      return window.location.href = 'tel:+380966079393' // kalush
    }
    if(selectedSpot?.name === "11"){
      return window.location.href = 'tel:+380960355161' // дніпро
    }
    if(selectedSpot?.name === "12"){
        return window.location.href = 'tel:+380983063935' // xmel
    }
    if(selectedSpot?.name === "13"){
        return window.location.href = "tel:+380983028995" // chern
    }
    if(selectedSpot?.name === "14"){
        return window.location.href = "tel:+380686757676" // if 
    }
  }


  function handleInstClick() {
    if(selectedSpot?.name === "2"){
      return window.location.href = 'https://www.instagram.com/my.thai.lozova/'; // lozova
    }
    if(selectedSpot?.name === "5"){
      return window.location.href = 'https://www.instagram.com/my.thai.uman/' // uman 
    }
    if(selectedSpot?.name === "8"){
      return window.location.href = 'https://www.instagram.com/my.thai.starokostiantyniv/' // staroconst
    }
    if(selectedSpot?.name === "10"){ 
      return window.location.href = 'https://www.instagram.com/mythai.kalush/' // kalush
    }
    if(selectedSpot?.name === "11"){
      return window.location.href = 'https://www.instagram.com/mythai.dnipro?igsh=MW42MWh0YThzcTB3bg==' // дніпро
    }
    if(selectedSpot?.name === "12"){
        return window.location.href = 'https://www.instagram.com/mythai.khmelnitskiy/' // xmel
    }
    if(selectedSpot?.name === "13"){
        return window.location.href = "https://www.instagram.com/mythai.chernivtsi/?igshid=MzRlODBiNWFlZA%3D%3D" // chern
    }
    if(selectedSpot?.name === "14"){
        return window.location.href = "https://www.instagram.com/mythai.if/" // if 
    }
  }
  

  return (
      <div id='body'>
            <div className='icon_phone_trigger' onClick={handlePhoneCall}>
                        <CallOutlinedIcon />  
            </div>
            <div style={{position: "fixed" , bottom: "80px" , left: "30px" , zIndex: 9999 ,width: "50px" , height: "50px" , display: "flex" , justifyContent: "center" , alignItems: "center" , borderRadius: "50%"}} onClick={handleInstClick}>
                        <img src={inst} style={{width: "40px" , height: "40px"}}/>  
            </div>
            {
                selectedSpot?.name === "2" || selectedSpot?.name === "5" || selectedSpot?.name === "11" || selectedSpot?.name === "8" || selectedSpot?.name === "9" || selectedSpot?.name === "10" || selectedSpot?.name === "12" || selectedSpot?.name === "13" || selectedSpot?.name === "14" ? <Discount/> : []
            }
            <HeaderConnect />
            <Slideshow />
            <DishesConnect />
            <BasketConnect/> 
      </div>
  )
}


    // if(selectedSpot?.name === "2"){
    //   return window.location.href = 'tel:+380660558522'; // lozova
    // }
    // if(selectedSpot?.name === "5"){
    //   return window.location.href = 'tel:+380992308969' // uman 
    // }
    // if(selectedSpot?.name === "11"){
    //   return window.location.href = 'tel:+380960355161' // staroconst
    // }
    // if(selectedSpot?.name === "8"){
    //   return window.location.href = 'tel:+380683494940' // slavuta
    // }
    // if(selectedSpot?.name === "9"){ 
    //   return window.location.href = 'tel:+380978335767' // kalush
    // }
    // if(selectedSpot?.name === "10"){ 
    //   return window.location.href = 'tel:+380966079393' // дрогобыч
    // }
    // if(selectedSpot?.name === "12"){
    //     return window.location.href = 'tel:+380983063935' // xmel
    // }
    // if(selectedSpot?.name === "13"){
    //     return window.location.href = "tel:+380983028995" // chern
    // }
    // if(selectedSpot?.name === "14"){
    //     return window.location.href = "tel:+380686757676" // if 
    // }

