import React from 'react'
import { useNavigate } from 'react-router'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import banner1 from "../images/banner2.jpg"
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

function Discount() {
    const navigation = useNavigate()
    const [imagePaths, setImagePaths] = useState([]);
    const selectedSpot = useSelector((state) => state.cardReducer?.spot);

    useEffect(() => {
      axios.get('https://www.mythai.ua/get-all-image-paths')
        .then(response => {
            const allPaths = response.data;
            const filteredPaths = allPaths.filter(image => !image.path.includes('uploads/mobile'));
            const filteredPaths2 = filteredPaths.filter(image => !image.path.includes('uploads/banners'));
            setImagePaths(filteredPaths2);
        })
        .catch(error => {
          console.log(error);
        });
    }, []);

      console.log(imagePaths)

  return (
    <div className='payment'>
        <div className='header'>
            <div className='header_content'>
                <div onClick={() => navigation("/")} className="payment_header_content">
                    <KeyboardArrowLeftIcon className='order_content_top_arrow' sx={{color:"red"}}/> 
                    <span style={{color: "red"}}>Назад до меню</span>
                </div>
            </div>
        </div>
        <div className='payment_body'>
            <div className='payment_body_content'>
                <img src={banner1}/>
                 {
                    selectedSpot?.name === "5" || selectedSpot?.name === "11" || selectedSpot?.name === "8" || selectedSpot?.name === "9" || selectedSpot?.name === "10" || selectedSpot?.name === "12"  ?  imagePaths?.map((image) => (
                    <img
                    key={image.name}
                    src={`https://www.mythai.ua${image.path}`}
                    alt={`${image.name}`}
                    />
                )) : []
                }
                <h4>Також діє акція -10% на протязі 7 днів до дня народження та 7 днів після🎁</h4>
                
            </div>
        </div>
    </div>
  )
}

export default Discount