import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Link  , useNavigate} from 'react-router-dom'
import { store } from '../store/store'

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { useEffect } from 'react';
import { useState } from 'react';
import { actionAddClient, actionBuy } from '../actions/Actions';
import { actionError, actionNumberOfOrder } from '../store/cardReducer';

import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';

const DeclinedOrder = () => {
  const navigation = useNavigate()
  const selectedSpot = useSelector((state) => state.cardReducer?.spot);

    const handleReload = () => {
        navigation("/");
        window.location.reload();
    }

    function handlePhoneCall() {
        if(selectedSpot?.name === "2"){
          return window.location.href = 'tel:+380660558522'; // Replace the phone number with the number you want to call
        }
        if(selectedSpot?.name === "5"){
          return window.location.href = 'tel:+380992308969'
        }
        if(selectedSpot?.name === "11"){
          return window.location.href = 'tel:+380960355161'
        }
        if(selectedSpot?.name === "8"){
          return window.location.href = 'tel:+380683494940'
        }
        if(selectedSpot?.name === "9"){ 
          return window.location.href = 'tel:+380978335767'
        }
        if(selectedSpot?.name === "10"){ 
          return window.location.href = 'tel:+380966079393'
        }
        if(selectedSpot?.name === "12"){ 
          return window.location.href = 'tel:+380983063935'
        }
        if(selectedSpot?.name === "13"){
            return window.location.href = "tel:+380983028995"
        }
         if(selectedSpot?.name === "14"){
            return window.location.href = "tel:+380686757676"
        }
      }

  return (
    <>
    {
      <div className='submit_order_content'>
          <div className='payment_body'>
              <div className='submit_order_box'>
                <h1>Вибачте , ваша оплата відхилена!</h1>
                <span>Спробуйте ще раз...</span>
                <p style={{color: "white"}}>Або замовляйте через оператора</p>
                <div className='icon_phone_trigger' onClick={handlePhoneCall}>
                      <CallOutlinedIcon />  
                </div>
                <button className='link_submit' onClick={() => handleReload()}>Повернутися на сайт</button>
              </div>
          </div>
      </div>
    }
  </>
  )
}

export default DeclinedOrder