import React from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import { Instagram } from '@mui/icons-material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';


function removePrefix(name) {
        // Remove "hi-thai — " prefix
        name = name.replace("hi-thai — ", "");

        name = name.replace("my-thai-kharkov — ", "");

        name = name.replace("limetest — ", "");
        
        return name;
}

function Restaurants({ spots, limetest, spotsFranchise, my_thai_dnepr }) {
  const navigation = useNavigate();

  const markers = [...spots, limetest[1], limetest[2], spotsFranchise[2], spots[3]]
    ?.map((spot) => {
      if (spot?.lat && spot?.lng) {
        return `markers=color:red%7Clabel:M%7C${spot.lat},${spot.lng}`;
      }
      return null; // Ensure we don't include invalid markers
    })
    .filter(Boolean) // Remove null or invalid entries
    .join("&");

  return (
    <div className="payment">
      <div className="header">
        <div className="header_content">
          <div onClick={() => navigation("/")} className="payment_header_content">
            <KeyboardArrowLeftIcon className="order_content_top_arrow" sx={{ color: "red" }} />
            <span style={{ color: "red" }}>Назад до меню</span>
          </div>
        </div>
      </div>

      <div className="restaurants_body">
        <div className="restaurant_body_content">
          <div className="map_our_stores_by_phone">
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?size=800x200&maptype=roadmap&${markers}&key=AIzaSyAXoZSuCo6ZFOpfKyEct58sJnJtc2qla7c`}
              alt="Map with markers"
            />
          </div>

{
  [spots[0], spots[1], spotsFranchise[8], spotsFranchise[2], limetest[0], spotsFranchise[4], spotsFranchise[6], spotsFranchise[7], spotsFranchise[5]].map((item, key) => {
    // Ensure item is defined and has the name or spot_name property
    if (item && (item?.name || removePrefix(item?.spot_name))) {
      if (item?.name === "My Thai") {
        return (
          <div className="restaurant_body_spots" key={item?.name || item.spot_name}>
            <div className="restaurant_body_spots_item">
              <h3>{item?.name || removePrefix(item.spot_name)}</h3>
              <span>
                <Instagram sx={{ color: "red" }} />
                <a href="https://www.instagram.com/my.thai.lozova/">@my.thai.lozovaya</a>
              </span>
            </div>
            <div className="restaurant_body_spots_adress">
              <PlaceOutlinedIcon />
              <h5>{item.address || item.spot_adress}</h5>
            </div>
          </div>
        );
      } else if (item?.name === "My Thai Умань") {
        return (
          <div className="restaurant_body_spots" key={item?.name || item.spot_name}>
            <div className="restaurant_body_spots_item">
              <h3>{item?.name || removePrefix(item.spot_name)}</h3>
              <span>
                <Instagram sx={{ color: "red" }} />
                <a href="https://www.instagram.com/my.thai.uman/">@my.thai.uman</a>
              </span>
            </div>
            <div className="restaurant_body_spots_adress">
              <PlaceOutlinedIcon />
              <h5>{item.address || item.spot_adress}</h5>
            </div>
          </div>
        );
      }}
            return null; // Return null if item is not valid
          })}
        </div>
      </div>
    </div>
  );
}

export const RestaurantsConnect = connect(state => ({ 
        spots: state.promise.franchise?.payload?.franchise["hi-thai"],
        limetest: state.promise.franchise?.payload?.franchise["limetest"],
        my_thai_dnepr: state.promise.franchise?.payload?.franchise["my-thai-dnepr"],
         spotsFranchise: state.promise.spots?.payload?.spots
  }), {
  
  })(Restaurants) 
  
  
//   else if(item.name || removePrefix(item.spot_name) === "Дрогобич"){
//                                  return (
//                                 <div className="restaurant_body_spots" key="14">
//                                   <div className="restaurant_body_spots_item">
//                                     <h3>Дрогобич</h3>
//                                     <span>
//                                             <Instagram  sx={{color: "red"}}/>
//                                             <a href='https://www.instagram.com/mythai.drogobych/'>@mythai.drogobych</a>
//                                     </span>
//                                   </div>
//                                   <div className="restaurant_body_spots_adress">
//                                     <PlaceOutlinedIcon />
//                                     <h5>улиця Гончарська, 4, Дрогобич, Львівська область, 82100</h5>
//                                   </div>
//                                 </div>
//                               );
//                             }
  
  
  
  
//   [...spots, spotsFranchise[2], spotsFranchise[3], spotsFranchise[4]].map((item) => (
//   <div className='restaurant_body_spots'>
//     <div className='restaurant_body_spots_item'>
//       <h3>{item.name || removePrefix(item.spot_name)}</h3>
//       <span>
//         <Instagram sx={{color: "#fb0004"}}/>
//         {item.name || removePrefix(item.spot_name) === "My Thai" ?
//           <a href={item.name === "My Thai" ? 'https://www.instagram.com/my.thai.lozovaya/' : item.name === "Another Restaurant" ? 'https://www.instagram.com/another.restaurant/' : item.name === "Third Restaurant" ? 'https://www.instagram.com/third.restaurant/' : 'https://www.instagram.com/fourth.restaurant/'}>{item.name === "My Thai" ? '@my.thai.lozovaya' : item.name === "Another Restaurant" ? '@another.restaurant' : item.name === "Third Restaurant" ? '@third.restaurant' : '@fourth.restaurant'}</a>
//           : null
//         }
//       </span>
//     </div>
//     <div className='restaurant_body_spots_adress'>
//       <PlaceOutlinedIcon/> 
//       <h5>{item.address || item.spot_adress}</h5>
//     </div>
//   </div>
// ))

  
//   <div className="map_our_stores_by_phone">
//                         <img
//                         src={`https://maps.googleapis.com/maps/api/staticmap?size=800x200&maptype=roadmap&${markers}&key=AIzaSyAXoZSuCo6ZFOpfKyEct58sJnJtc2qla7c`}
//                         alt="Map with markers"
//                         />
//                 </div>

// {
//                              item.name || removePrefix(item.spot_name) === "Старокостянтинів" ?   
//                              <div className='restaurant_body_spots_item'>
//                                 <h1>Старокостянтинів</h1>
//                                 <span>
//                                     <a href='/'>тимчасово не працює</a>
//                                 </span> 
//                              </div>
//                                 : []
//                             }
//                             {
//                             item.name === "My Thai Умань" ?  
//                                 <div className='restaurant_body_spots_item'>
//                                     <h1>Умань</h1>
//                                     <span>
//                                         <Instagram  sx={{color: "#fb0004"}}/>
//                                         <a href='https://www.instagram.com/my.thai.uman/'>@my.thai.uman jjjjjj</a>
//                                     </span> 
//                                 </div>
//                                  : []
//                             }

                        
                        
                        //  <div className='restaurant_body_spots_adress'>
                        //         <PlaceOutlinedIcon/> 
                        //         <h5>{item.address}</h5>
                        //     </div>