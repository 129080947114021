import React from 'react'
import { useNavigate } from 'react-router'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

function Contacts() {
    const navigation = useNavigate()
  return (
    <div className='payment'>
        <div className='header'>
            <div className='header_content'>
                <div onClick={() => navigation("/")} className="payment_header_content">
                    <KeyboardArrowLeftIcon className='order_content_top_arrow' sx={{color:"red"}}/> 
                    <span style={{color: "red"}}>Назад до меню</span>
                </div>
            </div>
        </div>
        <div className='payment_body'>
            <div className='payment_body_content'>
                <h1>Контакти</h1>
                  <h4 style={{ paddingBottom: '4%'}}>м. Лозова, мікрорайон 2 буд 16<br/> - тел. <b>0660558522</b></h4>
                <h4 style={{ paddingBottom: '4%'}}>ІПН 3495103338 mythai@gmail.com</h4>
                <hr/> 
                <h4 style={{ paddingBottom: '4%'}}>м. Умань, вул. Європейська 54 <br/> - тел. <b>0992308969</b></h4>
                <h4 style={{ paddingBottom: '4%'}}>ІПН 3495103338 mythai@gmail.com</h4>
                <hr/> 
            </div>
        </div>
    </div>
  )
}



//   <h4 style={{ paddingBottom: '4%'}}>м. Лозова, мікрорайон 2 буд 16<br/> - тел. <b>0660558522</b></h4>
//                 <h4 style={{ paddingBottom: '4%'}}>ІПН 3495103338 mythai@gmail.com</h4>
//                 <hr/> 
//                 <h4 style={{ paddingBottom: '4%'}}>м. Умань, вул. Європейська 54 <br/> - тел. <b>0992308969</b></h4>
//                 <h4 style={{ paddingBottom: '4%'}}>ІПН 3495103338 mythai@gmail.com</h4>
//                 <hr/> 
//                 <h4 style={{ paddingBottom: '4%'}}>м. Старокостянтинів, вул. князя Костянтина Острозького, 9 <br/> - тел. <b>0683494940</b></h4>
//                 <hr/> 
//                 <h4 style={{ paddingBottom: '4%'}}>м. Славута, вул. Миру, 102а <br/> - тел. <b>0978335767</b></h4>
//                 <hr/> 
//                 <h4 style={{ paddingBottom: '4%'}}>м. Калуш, вул. Степана Бандери, 3 <br/> - тел. <b>0966079393</b></h4>
//                 <hr/> 
//                 <h4 style={{ paddingBottom: '4%'}}>м. Дрогобич, вулиця Гончарська, 4<br/> - тел. <b>0960355161</b></h4>
//                 <hr/> 
//                 <h4 style={{ paddingBottom: '4%'}}>м.Хмельницький, вулиця Кам'янецька вулиця, 54<br/> - тел. <b>0983063935</b></h4>
//                 <hr/> 
//                 <h4 style={{ paddingBottom: '4%'}}>м.Чернівці,вулиця Заньковецької, 10<br/> - тел. <b>0983028995</b></h4>
//                 <h4 style={{ paddingBottom: '4%'}}>ІПН 3699902287 mythaiCher@gmail.com</h4>
//                 <hr/> 
//                 <h4 style={{ paddingBottom: '4%'}}>м.Івано-Франківськ, вулиця Галицька, 34<br/> - тел. <b>0686757676</b></h4>
//                 <h4 style={{ paddingBottom: '4%'}}>ІПН 3699902287 mythaiIf@gmail.com</h4>
//                 <hr/> 

export default Contacts