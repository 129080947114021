import React from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useLocation, useNavigate } from 'react-router'
import { store } from '../store/store';
import { connect, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import Input from '@mui/joy/Input';
import moment from "moment";
import StorefrontIcon from '@mui/icons-material/Storefront';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { actionCartAdd, actionCartChange, actionCartRemove, actionError, actionNumberOfOrder, actionSpotSelect, actionUserInfo} from '../store/cardReducer';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Autocomplete, LoadScript } from '@react-google-maps/api';
import { Skeleton } from '@mui/material';
import { actionRequestOrder } from '../actions/Actions';
import { useRef } from 'react';
import { useLayoutEffect } from 'react';
import { ParticlesContainer } from '../App';
import CryptoJS from 'crypto-js';
import { SelectAdress } from './Basket';
import axios from 'axios';

import Cookies from 'js-cookie';

const workHours = ["Доставити швидше", "10:30" , "11:00" , "11:30" , "12:00" , "12:30" , "13:00" , "13:30" , "14:00" , "14:30" , "15:00" , "15:30" , "16:00" , "16:30" , "17:00" , "17:30" , "18:00" , "18:30" , "19:00" , "19:30" , "20:00" , "20:30" , "21:00"]
const getBySelf = ["Заберу раніше", "10:30" , "11:00" , "11:30" , "12:00" , "12:30" , "13:00" , "13:30" , "14:00" , "14:30" , "15:00" , "15:30" , "16:00" , "16:30" , "17:00" , "17:30" , "18:00" , "18:30" , "19:00" , "19:30" , "20:00" , "20:30" , "21:00"]


const storedName = localStorage.getItem('name');
const storedPhone = localStorage.getItem('phone')
const storedAdress = localStorage.getItem('user_adress')

function updateProductId(products, productsFranchise) {
  const updatedProducts = products?.map(product => {
    const franchiseProduct = productsFranchise?.find(franchise => franchise.product_name === product.name);
    if (franchiseProduct) {
      return {
        ...product,
        product_id: franchiseProduct?.product_id,
        price: franchiseProduct?.product_price
      };
    } else {
      return product;
    }
  });
    return updatedProducts;
  }


const BasketMobile = ({ }) => {
  const navigation = useNavigate()
  const location = useLocation();
  const { state } = location;
  const selectedSpot = useSelector((state) => state.cardReducer?.spot?.spot_adress);

  const selectedSpotName = useSelector((state) => state.cardReducer?.spot)
  
  const [cookieAdded, setCookieAdded] = useState(false);

  const [selectedValue, setSelectedValue] = useState('delivery');
  const [selectAdress , setSelectAdress] = useState(false)
  const currentHour = moment().hour()
  const currentMinute = moment().minute()

  const [adress , setAdress] = useState('')
  const [room , setRoom] = useState('')

  let {price,products,productsMyThaiDnepr,productsFranchise,deliveryPrice, comment , productsStaroconst } = state

  const [userCookies, setUserCookies] = useState({ name: "",
    phone: "" ,
    adress : "" ,
    service_mode : "", 
    productId : "",
    selectedTime : "", 
    deliveryPrice : "", 
    cmt : ""});

  const timeWork = []
  const selfGet = []  

  const [totalChopsticks , setTotalChopsticks] = useState({ normalChopsticks: "" , educationalChopsticks: ""})

  let cmt = comment + "  |   "  + totalChopsticks.normalChopsticks + totalChopsticks.educationalChopsticks

  const [service_mode , setServiceMode] = useState(3)

  const [selectedTime , setSelectedTime] = useState('')

  const [paymentMetod,setPaymentMetod] = useState({
    paymentMetod: 'card'
  })

  const [isFirstElementRed, setIsFirstElementRed] = useState(false);
  const [isSecondElementRed, setIsSecondElementRed] = useState(false);

  const [normalChopsticksCount, setNormalChopsticksCount] = useState(0);
  const [educationalChopsticksCount, setEducationalChopsticksCount] = useState(0);
  
  useEffect(() => {
        if(selectedSpotName?.name === "2" || selectedSpotName?.name === "3" || selectedSpotName?.name === "4" || selectedSpotName?.name === "6" || selectedSpotName?.name === "7" || selectedSpotName?.name === "8" || selectedSpotName?.name === "9" || selectedSpotName?.name === "11" || selectedSpotName?.name === "10" || selectedSpotName?.name === "12" || selectedSpotName?.name === "13" || selectedSpotName?.name === "14"){
            setIsFirstElementRed(true)
        }
    },[selectedSpotName])


  const handleIncrementNormalChopsticks = () => {
    setNormalChopsticksCount(normalChopsticksCount + 1);
    setTotalChopsticks({
      ...totalChopsticks,
      normalChopsticks:  " ЗВИЧАЙНІ ПАЛ : "  + (normalChopsticksCount + 1)  + "  |"
    })
  };

  const handleDecrementNormalChopsticks = () => {
    if (normalChopsticksCount > 0) {
      setNormalChopsticksCount(normalChopsticksCount - 1);
      setTotalChopsticks({
        ...totalChopsticks,
        normalChopsticks: " ЗВИЧАЙНІ ПАЛ : " + (normalChopsticksCount - 1) + "  |"
      })
    }
  };

  const handleIncrementEducationalChopsticks = () => {
    setEducationalChopsticksCount(educationalChopsticksCount + 1);
    setTotalChopsticks({
      ...totalChopsticks,
      educationalChopsticks:  " НАВЧАЛЬНІ ПАЛ : "  + (educationalChopsticksCount + 1)
    })
  };

  const handleDecrementEducationalChopsticks = () => {
    if (educationalChopsticksCount > 0) {
      setEducationalChopsticksCount(educationalChopsticksCount - 1);
      setTotalChopsticks({
        ...totalChopsticks,
        educationalChopsticks: " НАВЧАЛЬНІ ПАЛ : " + (educationalChopsticksCount - 1) 
      })
    }
  };

  function generateRandomString() {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // Допустимые символы
    let randomString = '';
    for (let i = 0; i < 2; i++) {
      randomString += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    for (let i = 0; i < 10; i++) {
      randomString += Math.floor(Math.random() * 10);
    }
    return randomString;
  }

  const handleSubmitOrder = (event , name, phone  , adress , service_mode ,products ,productsFranchise ,  productsStaroconst  ,productsMyThaiDnepr ,  selectedTime, mainTime , deliveryPrice , cmt , navigation ) => { 
    let productId;
    
    if(store.getState().cardReducer?.spot?.name === "2" || store.getState().cardReducer?.spot?.name === "5" || store.getState().cardReducer?.spot?.name === "13" || store.getState().cardReducer?.spot?.name === "14" || store.getState().cardReducer?.spot?.name === "11"){
            productId = updateProductId(products , productsFranchise)
    }
    
    if(store.getState().cardReducer?.spot?.name === "8" || store.getState().cardReducer?.spot?.name === "9" || store.getState().cardReducer?.spot?.name === "10" || store.getState().cardReducer?.spot?.name === "12"){
            productId = updateProductId(products , productsStaroconst)
    }
 
    // !!!!!! create ordersubmit and declinedPayment
    
    store.dispatch(actionUserInfo(
    name , 
    phone ,
    adress ,
    service_mode, 
    productId,
    selectedTime ? selectedTime : mainTime, 
    deliveryPrice, 
    cmt
    ))
    
    let payment = 0;
    
    if (isSecondElementRed && (selectedSpotName?.name === "5" || selectedSpotName?.name === "11")) {
        payment = 1;
    } else {
        payment = 0;
    }
    
    
    const addCookies = () => {
        Cookies.remove('userCookies');
    // fbq('track', 'Purchase', { value: `${price}`, currency: 'UAH' });
        Cookies.set('userCookies', JSON.stringify({
          name: name,
          phone: phone,
          address: adress,
          service_mode: service_mode,
          productId: productId,
          selectedTime: selectedTime ? selectedTime : mainTime,
          deliveryPrice: deliveryPrice,
          comment: cmt,
          payment: payment,
          price: selectedValue === "getSelf" ? price : price + deliveryPrice
        }), { expires: 1 });   
    }
    
    //  price + deliveryPrice
    
    if(isFirstElementRed){
      addCookies()  
      navigation("/orderSubmit")
    }else{
      addCookies()
      setTimeout(() => handleSendDataToWayForPay(event), 1000)
    }
    
  }

    const filteredCart = products?.slice(0, -1);
    const totalCount = filteredCart?.map(item => item.count)?.reduce((total, count) => total + count, 0);

    const [ dataToSend, setDataToSend ] = useState({
      merchantAccount:  "",
      merchantDomainName: "mythai.ua",
      orderReference: `${generateRandomString()}`,
      orderDate: `1415379863`,
      amount: "" ,
      currency: "UAH",
      productName: filteredCart?.map((item => (`${item.name}`))), 
      productName1: "Доставка",
      productCount: `${totalCount}`,
      productCount1: "1",
      productPrice: price,
      productPrice1: "",
    });
    
    useEffect(() => {
        if(service_mode === 3){
           if(selectedSpotName?.name === "14"){
                setDataToSend({...dataToSend, merchantAccount: "mythai_ua2", amount: price + deliveryPrice , productPrice1: deliveryPrice})
           }
           if(selectedSpotName?.name === "13"){
                setDataToSend({...dataToSend, merchantAccount: "mythai_ua3", amount: price + deliveryPrice , productPrice1: deliveryPrice })
           }
           if(selectedSpotName?.name === "12"){
                setDataToSend({...dataToSend, merchantAccount: "mythai_ua4", amount: price + deliveryPrice , productPrice1: deliveryPrice })
           }
           if(selectedSpotName?.name === "11"){
                setDataToSend({...dataToSend, merchantAccount: "mythai_ua6", amount: price + deliveryPrice , productPrice1: deliveryPrice })
           }
           if(selectedSpotName?.name === "10"){
                setDataToSend({...dataToSend, merchantAccount: "mythai_ua5", amount: price + deliveryPrice , productPrice1: deliveryPrice })
           }
           if(selectedSpotName?.name === "5"){
                setDataToSend({...dataToSend, merchantAccount: "hi_thai_postershop_me", amount: price + deliveryPrice , productPrice1: deliveryPrice })
           } // uman 
            }else{
               setDataToSend({...dataToSend, amount: price , productPrice1: 0})
            }
    },[service_mode])

    // !!!!!!! помни что products нету в сабміте !!!!!! делівері дальше проверяй 

    const [generatedSignature , setGeneratedSignature] = useState("")
    
    function apiKey () {
        if(selectedSpotName?.name === "14"){
            return 'b4b6440f7e5b9dee91a8ae9c0ecef216cdf24cd3'
        }
        if(selectedSpotName?.name === "13"){
            return '19bccda7233392449ad3ceb0abce40bc70cdc7db'
        }
        if(selectedSpotName?.name === "12"){
            return 'bfc420e2b620b0da3da198734dc087944e91aad7'
        }
        if(selectedSpotName?.name === "10"){
            return '11fc4db51930360ce15675b87e3f737161eb9eec'
        }
        if(selectedSpotName?.name === "11"){
            return 'aa2acd077b0d25327b9619c7207d7fff98a8f3ba'
        }
        if(selectedSpotName?.name === "5"){
            return '10c3728bbdb0dd887b32a5804f13078e77853f36'
        }// uman    
        return 'default_api_key'
    }

    // !!!!!!!!!!! разберісь с єтім
    var handleSendDataToWayForPay = (event) => {
      const secretKey = apiKey ();
      event.preventDefault();
      function concatenateObjectToStringWithSemicolon(obj) {
        let result = '';
        console.log(obj)
      
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            result += obj[key].toString() + ';';
          }
        }

        // Remove the trailing semicolon
        if (result.endsWith(';')) {
          result = result.slice(0, -1);
        }
      
        return result;
      }
      const concatenatedString = concatenateObjectToStringWithSemicolon(dataToSend);
  
      try {
        const hmacMd5Signature = CryptoJS.HmacMD5(concatenatedString, secretKey).toString();
        setGeneratedSignature(hmacMd5Signature);
        console.log('HMAC_MD5 Signature:', hmacMd5Signature);
      } catch (error) {
        console.error('Error generating HMAC_MD5 Signature:', error);
      }
      const form = document.getElementById('form');
      setTimeout(() => form.submit(),1000)
    };

    // ! логично будет сделать после нажатие кнопки переводить человека уже на страничку submit и там разбираться если человек провел платеж и он успешен то тогда отправляем в постер если нет то тогда отрисовываем что оплата была не успешно попробуйте еще раз 


    const renderForm = () => {
      
      return (
        <div style={{visibility: "hidden" , height: "0px"}}>
          <form method="post" action="https://secure.wayforpay.com/pay" acceptCharset="utf-8" id="form">
                      <input name="merchantAccount"  value={dataToSend.merchantAccount} />
                      <input name="merchantAuthType" value="SimpleSignature" />
                      <input name="merchantDomainName" value={dataToSend.merchantDomainName} />
                      <input name="orderReference" value={dataToSend.orderReference} />
                      <input name="orderDate" value={dataToSend.orderDate} />
                      <input name="amount" value={dataToSend.amount}/> 
                      <input name="currency" value={dataToSend.currency} />
                      <input name="orderTimeout" value="49000" />
                      <input name="productName[]"  value={dataToSend.productName} onChange={(e) => setDataToSend({ ...dataToSend, productName: e.target.value })}/>
                      <input name="productName[]"  value={dataToSend.productName1} onChange={(e) => setDataToSend({ ...dataToSend, productName1: e.target.value })}/>
                      <input name="productPrice[]" value={dataToSend.productPrice} onChange={(e) => setDataToSend({ ...dataToSend, productPrice: e.target.value })}/>
                      <input name="productPrice[]" value={dataToSend.productPrice1} onChange={(e) => setDataToSend({ ...dataToSend, productPrice1: e.target.value })}/>
                      <input name="productCount[]" value={dataToSend.productCount} />
                      <input name="productCount[]" value="1" /> 
                      <input name="clientFirstName" value="Вася"  />
                      <input name="defaultPaymentSystem" value="card"/>
                      <input name="merchantSignature" value={generatedSignature} />
          </form>
        </div>

      );
    }

    useEffect(() => {
      const name = 'name'
      const phone = 'phone'
      const address = 'address'
      if(storedName) {
        setFormData({
          ...formData,
          [name] : storedName, 
          [phone]: storedPhone,
          [address] : storedAdress
        });
        setAdress(storedAdress)
      }
    }, [storedAdress])
    
    useEffect(() => {
        
    },[service_mode])

    const toggleFirstElementColor = () => {
      setIsSecondElementRed(false);
      setIsFirstElementRed(true);
      setPaymentMetod({paymentMetod: "cash"})
    };

    const toggleSecondElementColor = () => {
      setIsSecondElementRed(true);
      setIsFirstElementRed(false);
      setPaymentMetod({paymentMetod: "card"})
    };

    const [formData , setFormData] = useState({
      name: '',
      phone: '',
      address: '',
    })

    const [errors , setErrors] = useState({
      name: '',
      phone: '',
      address: '',
    })

    useEffect(() => {
      const name = 'name'
      if(storedName !== '') {
        setFormData({
          ...formData,
          [name] : storedName
        });
      }
    }, [])

    useEffect(() => {
      const name = 'phone'
      if(storedPhone !== ''){
        setFormData({
          ...formData,
          [name]: storedPhone
        })
      }
    }, [])
    
    const validatePhone = (value) => {
      const regex = /^\+380\d{9}$/;
      if(regex.test(value)){
        return '';
      }
      return "Введіть номер телефону , +380";
    };
    
    const handleInputChange = (event) => {
      const {name , value} = event.target;
      setFormData({
        ...formData,
        [name]: value,
      })
    }

    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    const phoneError = validatePhone(formData.phone);

    const handleSubmit = (event) => {
      event.preventDefault();
      const form = document.querySelector('#my-form');

      const phoneError = validatePhone(formData.phone);
      setErrors({
        ...errors,
        phone: phoneError,
      });
      
       window.gtag('event', 'purchase', {
      event_category: 'button_click',
      event_label: 'Purchase Button Clicked'
    });

    //   const hasErrors = Object.values(errors).some((error) => error);
      if(!phoneError){
        console.log(formData);
        localStorage.setItem("name" , formData.name);
        localStorage.setItem("phone" , formData.phone);
        localStorage.setItem("user_adress" , `${adress} ${room}`);
        
        if(isFirstElementRed || isSecondElementRed){
        handleSubmitOrder( event ,formData.name, formData.phone  , `${adress} /подъезд/квартира ${room}`, service_mode ,products ,productsFranchise , productsStaroconst , productsMyThaiDnepr ,  selectedTime, mainTime , deliveryPrice , cmt , navigation);
        }
      }
    }
    
    let mainTime = currentHour + ":" + currentMinute;
    let time;
  
    if(mainTime > "00:00"){
      time = "00:00"
    }
  
    if(mainTime > "10:30"){
      time = mainTime
    }
  
    const disableHours = () => {
      for(let i = 0; i < workHours.length; i++) {
        if(time <= workHours[i]) {
          timeWork.push(workHours[i]);
        }
      }
      for(let i = 0; i < getBySelf.length; i++) {
        if(time <= getBySelf[i]) {
          selfGet.push(getBySelf[i]);
        }
      }
    }
    disableHours()

    console.log(products)

    // !!!!!!
    useEffect(() => {
      // Прокручиваем страницу вверх при монтировании компонента
      window.scrollTo(0, 0);
    }, []);


  return (
    <>
    <div className='basketMobile' style={{overflowY: "scroll"}}>
    <style> @import url('https://fonts.cdnfonts.com/css/hitch-hike'); </style>
       <div className='header' style={{backgroundColor: "#4d3e3f" , width: "100%" , textAlign: "center" , position: "fixed" , top:0 , zIndex: "999"}}>
            <div className='header_content' style={{backgroundColor: "#4d3e3f" , width: "100%" , textAlign: "center"}}>
                <div onClick={() => navigation("/")} className="payment_header_content" style={{width: "90%" , textAlign: "center"}}>
                    <KeyboardArrowLeftIcon className='order_content_top_arrow' sx={{color:"red"}}/> 
                    <span style={{fontFamily: 'Hitch Hike,sans-serif', width: "100%" , fontSize: "25px"}}>MyThai</span>
                </div>
            </div>
        </div>
        <div className='order_contants_details' style={{ paddingTop: "30%"}}>
              <form onSubmit={handleSubmit} id="my-form">
                  <Input 
                  startDecorator={<PermIdentityOutlinedIcon sx={{
                  backgroundColor: '#171010',
                  color: "silver"
                  }}/>}
                  type="text"
                  name='name'
                  value={formData.name}
                  onChange={handleInputChange}
                  endDecorator={ errors.name ? <ErrorOutlineOutlinedIcon sx={{
                    backgroundColor: 'inherit',
                    color: "red"
                    }}/> : []}
                  className="contacts_input" 
                  placeholder="Ім'я" 
                  sx={{backgroundColor: "#171010", border: 'none' 
                  }} 
                  />
    
                  <Input startDecorator={<CallOutlinedIcon sx={{
                  backgroundColor: '#171010',
                  color: "silver"
                  }}/>} 
                  type="text"
                  name='phone'
                  value={formData.phone}
                  onChange={handleInputChange}
                  endDecorator={ errors.phone ? <ErrorOutlineOutlinedIcon sx={{
                    backgroundColor: 'inherit',
                    color: "red"
                    }}/> : []}
                  className="contacts_input" placeholder="Номер телефону +380..."
                  sx={{backgroundColor: "#171010" , color: 'white'}} 
                  />
              </form>
            </div>
                {errors.phone && <div style={{color: "red" , backgroundColor: "#4e3f3f" , padding: 5 , textAlign: "center"}}>{errors.phone}</div>}
            <div className='delivery'>
              <div className='delivery_title'>
              {
                selectedValue === 'delivery' ? <span style={{backgroundColor: "inherit" ,color: 'white'}}>Доставлення</span> : <span style={{backgroundColor: "inherit" , color: 'white'}}>Самовивіз</span>
              }
              </div>
              <div className='delivery_select'>
                  <label className="labl" onClick={() => setServiceMode(3)}>
                      <input type="radio" value="delivery" name="gender" onChange={handleChange} checked={selectedValue === 'delivery'}/> 
                      <div>Доставлення</div>
                  </label>
                  <label className="labl" onClick={() => setServiceMode(2)}>
                      <input type="radio" value="getSelf" name="gender" onChange={handleChange} checked={selectedValue === 'getSelf'}/> 
                      <div>Самовивіз</div>
                  </label>
              </div>
            </div>
              {
                selectedValue === "delivery" ? 
                <div className='order_contants_details'>
                    <Input 
                    startDecorator={<PlaceOutlinedIcon sx={{
                    backgroundColor: '#171010',
                    color: "silver"
                    }}/>} 
                    value={adress ? adress : []}
                    onClick={() => {
                        setSelectAdress(true)
                    }}
                    readOnly
                    autoComplete="new-password"
                    className="contacts_input" 
                    placeholder="Вкажіть адресу доставлення" 
                    sx={{backgroundColor: "#171010", border: 'none'
                    }} 
                    />
                    <div className='time_choose'>
                      <WatchLaterOutlinedIcon className='time_icon'/>
  
                      <select  className="contacts_input" onChange={(e) => setSelectedTime(e.target.value)}>
  
                      {timeWork.length > 0 ? timeWork.map((e, i) => (
                        <option key={i} value={e}>
                          {e}
                        </option>
                      )) : (
                        <option value="">No available hours</option>
                      )}
  
                      </select>
                    </div>
                </div>
                : []
              }
              
              {
                selectedValue === "getSelf" ? 
                    <div className='order_contants_details'>
                          <Input 
                            startDecorator={<StorefrontIcon sx={{
                            backgroundColor: '#171010',
                            color: "white"
                            }}/>}
                            autoComplete="new-password"
                            disabled
                            value={selectedSpot}
                            className="contacts_input" 
                            // placeholder={selectedSpot}
                            sx={{backgroundColor: "#171010", border: 'none'
                            }} 
                            />
              
                            <div className='time_choose'>
                                <WatchLaterOutlinedIcon className='time_icon'/>
                                <select  className="contacts_input"  onChange={(e) => setSelectedTime(e.target.value)}>
                                {selfGet.length > 0 ? selfGet.map((e, i) => (
                                  <option key={i} value={e}>
                                    {e}
                                  </option>
                                )) : (
                                  <option value="">No available hours</option>
                                )}
                                </select>
                            </div>
                    </div>
                : []
              }
               <div className='delivery'>
                <div className='delivery_title'>
                  <span style={{ backgroundColor: "inherit", color: 'white' }}>Кількість приборів</span>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '40px',
                    backgroundColor: "#171010",
                    marginBottom: '10px',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    position: 'relative',
                    alignItems: 'center',
                    color: 'white',
                  }}
                >
                    <div style={{ position: "absolute", left: "10px", fontSize: "18px" , width: "50%" }}>Звичайні палички</div>
                    <div style={{ position: "absolute", width: "50%", right: "0", height: "100%", display: "flex" , justifyContent: "center" , alignItems: "center" }}>
                      <button style={{ position: "absolute", right: 80 , height: "30px" ,backgroundColor: "red"  ,  color: "white" , fontSize: "15px" , border: "none" , borderRadius: "5px"}} onClick={handleIncrementNormalChopsticks}>+</button>
                        <span style={{ position: "absolute", right: 57 , fontWeight: "bold" }}>{normalChopsticksCount}</span>
                      <button style={{ position: "absolute", right: 10 ,  height: "30px" , backgroundColor: "red" , color: "white" ,fontSize: "15px" , border: "none",borderRadius: "5px" }} onClick={handleDecrementNormalChopsticks}>-</button>
                    </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '40px',
                    marginBottom: '15px',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    position: 'relative',
                    alignItems: 'center',
                    backgroundColor: "#171010",
                    color: "white"
                  }}
                >
                    <div style={{ position: "absolute", left: "10px", fontSize: "18px" }}>Навчальні палички</div>
                    <div style={{ position: "absolute", width: "49%", right: "0", height: "100%", display: "flex" , justifyContent: "center" , alignItems: "center" }}>
                        <button style={{ position: "absolute", right: 80 , height: "30px" ,backgroundColor: "red"  ,  color: "white" , fontSize: "15px" , border: "none" , borderRadius: "5px"}} onClick={handleIncrementEducationalChopsticks}>+</button>
                          <span style={{ position: "absolute", right: 57 , fontWeight: "bold" }}>{educationalChopsticksCount}</span>
                        <button style={{ position: "absolute", right: 10 ,  height: "30px" , backgroundColor: "red" , color: "white" ,fontSize: "15px" , border: "none",borderRadius: "5px" }} onClick={handleDecrementEducationalChopsticks}>-</button>
                    </div>
                </div>
                
              </div>
              {/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
              <div className='delivery'>
                <div className='delivery_title'>
                    <span style={{backgroundColor: "inherit" ,color: 'white'}}>Оберіть спосіб оплати</span> 
                </div>
                <div
                    style={{
                      width: '100%',
                      backgroundColor: isFirstElementRed ? 'red' : 'silver',
                      height: '40px',
                      marginBottom: '15px',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      position: 'relative',
                      alignItems: 'center',
                      color: isFirstElementRed ? 'black' : 'white',
                    }}
                    onClick={toggleFirstElementColor}
                  >
                    <div style={{ position: "absolute" , left: "10px" , backgroundColor: isFirstElementRed ? 'red' : ''}}>💵</div>
                    <div style={{ position: "absolute" , left: "40px" , fontSize: "18px" ,  backgroundColor: isFirstElementRed ? 'red' : ''}}>Готівка</div>
                  </div>
                  {/* <AccountBalanceWalletOutlinedIcon/> <CreditCardOutlinedIcon/> */}

                  <div
                    style={  !( selectedSpotName?.name === "9" || selectedSpotName?.name === "8" || selectedSpotName?.name === "7" || selectedSpotName?.name === "6" || selectedSpotName?.name === "4" || selectedSpotName?.name === "3" || selectedSpotName?.name === "2" || selectedSpotName?.name === "1" || selectedSpotName?.name === "11" || selectedSpotName?.name === "10" || selectedSpotName?.name === "12"  || selectedSpotName?.name === "13" || selectedSpotName?.name === "14")  ?  {
                      width: '100%',
                      backgroundColor: isSecondElementRed ? 'red' : 'silver',
                      height: '40px',
                      marginBottom: '15px',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      position: 'relative',
                      alignItems: 'center',
                      color: isSecondElementRed ? 'black' : 'white',
                    } : 
                    {display: "none"}}
                    onClick={toggleSecondElementColor}
                  >
                       <div style={{ position: "absolute" , left: "10px" , backgroundColor: isSecondElementRed ? 'red' : 'silver'}}>💳</div>
                       <div style={{ position: "absolute" , left: "40px" , fontSize: "18px" ,  backgroundColor: isSecondElementRed ? 'red' : 'silver'}}>Карта</div>
                  </div>
              </div>
              <div className='order_bottom' style={{display: "flex", justifyContent: "center"}}>
                <div className='order_bottom_price'>
                    <span style={{alignItems: "center"}}>Сумма замовлення: <b style={{paddingBottom: "10px"}}>{price + " грн"}</b> </span>
                    {selectedValue === "getSelf"  ? [] : <span style={{alignItems: "center"}}>Доставлення: <b style={{paddingBottom: "10px"}}>{deliveryPrice}грн</b></span>}
                </div>
                <button type='submit' className='link_bottom' onClick={(e) => {
                      handleSubmit(e)}}>
                       Оформити за <b>{(service_mode == "2" ? price : price  + deliveryPrice )}грн</b>
                </button>
              </div>
              {renderForm()}
              
    </div>
    <SelectAdress selectAdress={selectAdress} setSelectAdress={setSelectAdress} setAdress={setAdress} adress={adress} setRoom={setRoom} />
    </>
  )
}

export const BasketMobileConnect = connect(state => ({ 
  
}), {

})(BasketMobile) 

