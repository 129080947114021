import React from 'react'
import { store } from '../store/store';
import { connect, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import Input from '@mui/joy/Input';
import moment from "moment";
import ReactPixel from 'react-facebook-pixel';
import StorefrontIcon from '@mui/icons-material/Storefront';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { actionCartAdd, actionCartChange, actionCartRemove, actionError, actionNumberOfOrder, actionSpotSelect, actionUserInfo} from '../store/cardReducer';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Autocomplete, LoadScript } from '@react-google-maps/api';
import { Skeleton } from '@mui/material';
import { Link , useNavigate } from 'react-router-dom';
import { actionRequestOrder } from '../actions/Actions';
import { useRef } from 'react';
import { useLayoutEffect } from 'react';
import { ParticlesContainer } from '../App';

import { Helmet } from 'react-helmet';
import { BasketMobileConnect } from './BasketMobile';

import Cookies from 'js-cookie';

const workHours = ["Доставити швидше", "10:30" , "11:00" , "11:30" , "12:00" , "12:30" , "13:00" , "13:30" , "14:00" , "14:30" , "15:00" , "15:30" , "16:00" , "16:30" , "17:00" , "17:30" , "18:00" , "18:30" , "19:00" , "19:30" , "20:00" , "20:30" , "21:00"]
const getBySelf = ["Заберу раніше", "10:30" , "11:00" , "11:30" , "12:00" , "12:30" , "13:00" , "13:30" , "14:00" , "14:30" , "15:00" , "15:30" , "16:00" , "16:30" , "17:00" , "17:30" , "18:00" , "18:30" , "19:00" , "19:30" , "20:00" , "20:30" , "21:00"]

const libraries = ['places'];
export const SelectAdress = ({selectAdress , setSelectAdress  , adress , room , setRoom , setAdress, setOrderActive}) => {
  const [adressError , setAdressError] = useState('')

  const [loadScriptKey, setLoadScriptKey] = useState(0);

  const autocompleteRef = useRef(null);

  useEffect(() => {
    if(selectAdress){
        setLoadScriptKey(prevKey => prevKey + 1);
    }
  }, [selectAdress])
  
  const handleSetAdress = (event) => {
        setAdress(event.target.value)
  }

  const handleSetRoom = (event) => {
        setRoom(event.target.value)
  }

  const handleChangeModal = () => {
        setSelectAdress(false)
        // setOrderActive(true)
  }
  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      console.log(place)
      if (place) {
        setAdress(place?.formatted_address);
      } else {
        console.log("No location available");
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const options = {
        componentRestrictions: {country: "ua"},
  }

  const handleSubmit = (adress , room) => {
        handleChangeModal()
        localStorage.setItem("user_adress" , adress && room ? `${adress} ${room}`: adress)
  }

  return (
    <div className={selectAdress ? "modal_basket active" : "modal_basket"} onClick={()=> setSelectAdress(false)}>
      <div className='modal_basket_content' onClick={e => e.stopPropagation()}>
          <div className='order_wrapper'>
              <div className='order_content'>
                <KeyboardArrowLeftIcon className='order_content_top_arrow' onClick={() => handleChangeModal()}/> 
                <div className='order_content_top'>
                  <span>Напишіть адресу</span>
                </div>
              </div>
              <div className='select_adress_content'>
                    <div className='select_adress_top'  sx={{borderRadius: 10}}> 
                          <span>Адреса доставлення</span>
                          <LoadScript googleMapsApiKey="AIzaSyD-3lAed2PzWnYQG9wHBnIKguUzfcVGw9Y"  libraries={libraries}  key={loadScriptKey}>
                            <Autocomplete
                              options={options}
                              onLoad={onLoad}
                              onPlaceChanged={() => onPlaceChanged()}
                              className="pac-container">
                              <input
                                type="text"
                                value={adress}
                                placeholder="Вкажіть адресу доставлення"
                                onChange={handleSetAdress}
                                className="pac-input"
                              />
                            </Autocomplete>
                          </LoadScript>) 
                    </div>
                    <div>
                          <span>Під’їзд, поверх, квартира</span>
                          <Input 
                            className="adress_input" 
                            value={room}
                            onChange={handleSetRoom}
                            sx={{backgroundColor: "#171010", border: 'none'
                            }} 
                            />
                    </div>
                    {adressError ? <div style={{color: "red" , display: "flex" , alignItems: "center"}}><ErrorOutlineOutlinedIcon sx={{backgroundColor: "inherit" , paddingRight: "5px"}}/>{adressError}</div> : []}
                    <button onClick={() => {
                    if (adress) {
                      handleSubmit(adress , room);
                    } else {
                      setAdressError("Введіть адресу");
                    }
                      }}>Зберегти</button>
              </div>
          </div>
      </div>
    </div>
  )
}


const storedName = localStorage.getItem('name');
const storedPhone = localStorage.getItem('phone')
const storedAdress = localStorage.getItem('user_adress')


  function updateProductId(products, productsFranchise) {
    const updatedProducts = products?.map(product => {
      const franchiseProduct = productsFranchise?.find(franchise => franchise.product_name === product.name);
      if (franchiseProduct) {
        return {
          ...product,
          product_id: franchiseProduct.product_id,
          price: franchiseProduct?.product_price
        };
      } else {
        return product;
      }
    });
      return updatedProducts;
    }
    


   const OrderModal = ({orderActive , setOrderActive , setOpenBasket , price , products , productsFranchise , productsStaroconst , productsMyThaiDnepr , deliveryPrice , comment ,status}) => {
    const selectedSpot = useSelector((state) => state.cardReducer?.spot?.spot_adress);
    const [selectedValue, setSelectedValue] = useState('delivery');
    const [selectAdress , setSelectAdress] = useState(false)
    const navigation = useNavigate()
    const currentHour = moment().hour()
    const currentMinute = moment().minute()
    const timeWork = []
    const selfGet = []  

    const [adress , setAdress] = useState('')
    const [room , setRoom] = useState('')
// !
    const [service_mode , setServiceMode] = useState(3)
  
    const [selectedTime , setSelectedTime] = useState('')
// !

    const [formData , setFormData] = useState({
      name: '',
      phone: '',
      address: ''
    })
    

    const [errors , setErrors] = useState({
      name: '',
      phone: '',
      address: ''
    })


    useEffect(() => {
      if(selectAdress === true){
        setOrderActive(false)
      }
    }, [orderActive , selectedValue , selectAdress])
    

    useEffect(() => {
      const name = 'name'
      const phone = 'phone'
      const address = 'address'
      if(storedName) {
        setFormData({
          ...formData,
          [name] : storedName, 
          [phone]: storedPhone,
          [address] : storedAdress
        });
        setAdress(storedAdress)
      }
    }, [storedAdress])
    
    // const runWayforpay = () => {
    // const wayforpay = new Wayforpay();

    // wayforpay.run({
    //   merchantAccount: 'mythai_ua',
    //   merchantDomainName: 'mythai.ua',
    //   authorizationType: 'SimpleSignature',
    //   merchantSignature: '517c098bd970ea9811ee5f9c1f8640ed4a5d0d4a',
    //   orderReference: 'DH783023',
    //   orderDate: '1415379863',
    //   amount: '1547.36',
    //   currency: 'UAH',
    //   productName: 'Процессор Intel Core i5-4670 3.4GHz',
    //   productPrice: '1000',
    //   productCount: '1',
    //   clientFirstName: 'Вася',
    //   clientLastName: 'Васечкин',
    //   clientEmail: 'some@mail.com',
    //   clientPhone: '380631234567',
    //   language: 'UA',
    // }, function (response) {
    //   // on approved
    // }, function (response) {
    //   // on declined
    // }, function (response) {
    //   // on pending or in processing
    // });
    
  //     useEffect(() => {
  //       const script = document.createElement('script');
  //       script.id = 'widget-wfp-script';
  //       script.type = 'text/javascript';
  //       script.src = 'https://secure.wayforpay.com/server/pay-widget.js';
  //       script.async = true;
  //       script.onload = runWayforpay;
    
  //       document.body.appendChild(script);
    
  //       return () => {
  //         document.body.removeChild(script);
  //       };
  //     }, []);
  // };

    const validatePhone = (value) => {
      const regex = /^\+380\d{9}$/;
      if(regex.test(value)){
        return '';
      }
      return "Введіть номер телефону ,який починається з +380....";
    };
    
    const handleInputChange = (event) => {
      const {name , value} = event.target;
      setFormData({
        ...formData,
        [name]: value,
      })
    }

    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const form = document.querySelector('#my-form');

      const phoneError = validatePhone(formData.phone);
      setErrors({
        ...errors,
        phone: phoneError,
      });
      
       window.gtag('event', 'purchase', {
      event_category: 'button_click',
      event_label: 'Purchase Button Clicked'
    });

    //   const hasErrors = Object.values(errors).some((error) => error);
      if(!phoneError){
        console.log(formData);
        localStorage.setItem("name" , formData.name);
        localStorage.setItem("phone" , formData.phone);
        localStorage.setItem("user_adress" , `${adress} ${room}`);

        handleSubmitOrder( event ,formData.name, formData.phone  , `${adress} /подъезд/квартира ${room}`, service_mode ,products ,productsFranchise , productsStaroconst , productsMyThaiDnepr , selectedTime, mainTime , deliveryPrice , comment , navigation);
        console.log(`${adress} / ${room}`)
      }
    }
    
    let mainTime = currentHour + ":" + currentMinute;
    let time;
  
    if(mainTime > "00:00"){
      time = "00:00"
    }
  
    if(mainTime > "10:30"){
      time = mainTime
    }
  
    const disableHours = () => {
      for(let i = 0; i < workHours.length; i++) {
        if(time <= workHours[i]) {
          timeWork.push(workHours[i]);
        }
      }
      for(let i = 0; i < getBySelf.length; i++) {
        if(time <= getBySelf[i]) {
          selfGet.push(getBySelf[i]);
        }
      }
    }
    disableHours()
    
    const [isFirstElementRed, setIsFirstElementRed] = useState(false);
    const [isSecondElementRed, setIsSecondElementRed] = useState(false);
    
    
  const handleSubmitOrder = (event , name, phone  , adress , service_mode ,products ,productsFranchise ,  productsStaroconst  ,productsMyThaiDnepr ,  selectedTime, mainTime , deliveryPrice , cmt , navigation ) => { 
    let productId;
    
    if(store.getState().cardReducer?.spot?.name === "2" || store.getState().cardReducer?.spot?.name === "5" || store.getState().cardReducer?.spot?.name === "11"  || store.getState().cardReducer?.spot?.name === "13" || store.getState().cardReducer?.spot?.name === "14"){
        productId = updateProductId(products , productsFranchise)
    }
    
    if(store.getState().cardReducer?.spot?.name === "8" || store.getState().cardReducer?.spot?.name === "9" || store.getState().cardReducer?.spot?.name === "10" || store.getState().cardReducer?.spot?.name === "12"){
        productId = updateProductId(products , productsStaroconst)
    }
 
    // !!!!!! create ordersubmit and declinedPayment
    
    store.dispatch(actionUserInfo(
    name , 
    phone ,
    adress ,
    service_mode, 
    productId,
    selectedTime ? selectedTime : mainTime, 
    deliveryPrice, 
    cmt
    ))
    
    let payment = 0;
    
    Cookies.remove('userCookies');
    // fbq('track', 'Purchase', { value: `${price}`, currency: 'UAH' });
    Cookies.set('userCookies', JSON.stringify({
      name: name,
      phone: phone,
      address: adress,
      service_mode: service_mode,
      productId: productId,
      selectedTime: selectedTime ? selectedTime : mainTime,
      deliveryPrice: deliveryPrice,
      comment: cmt,
      payment: payment,
      price: price + deliveryPrice
    }), { expires: 1 });
    
      navigation("/orderSubmit")
    
  }
 
  
    return (
      <>
      <div className={orderActive ? "modal_basket active" : "modal_basket"} onClick={()=> setOrderActive(false)}>
        <div className='modal_basket_content' onClick={e => e.stopPropagation()}>
          <div className='order_wrapper'>
            <div className='order_content'>
            <KeyboardArrowLeftIcon className='order_content_top_arrow' onClick={() => setOrderActive(false)}/>
              <div className='order_content_top'>
                <span>Замовлення</span>
              </div>
            </div>
            <div className='order_contants_details'>
              <form onSubmit={handleSubmit} id="my-form">
                  <Input 
                  startDecorator={<PermIdentityOutlinedIcon sx={{
                  backgroundColor: '#171010',
                  color: "silver"
                  }}/>}
                  type="text"
                  name='name'
                  value={formData.name}
                  onChange={handleInputChange}
                  endDecorator={ errors.name ? <ErrorOutlineOutlinedIcon sx={{
                    backgroundColor: 'inherit',
                    color: "red"
                    }}/> : []}
                  className="contacts_input" 
                  placeholder="Ім'я" 
                  sx={{backgroundColor: "#171010", border: 'none' 
                  }} 
                  />
    
                  <Input startDecorator={<CallOutlinedIcon sx={{
                  backgroundColor: '#171010',
                  color: "silver"
                  }}/>} 
                  type="text"
                  name='phone'
                  value={formData.phone}
                  onChange={handleInputChange}
                  endDecorator={ errors.phone ? <ErrorOutlineOutlinedIcon sx={{
                    backgroundColor: 'inherit',
                    color: "red"
                    }}/> : []}
                  className="contacts_input" placeholder="Номер телефону +380..."
                  sx={{backgroundColor: "#171010" , color: 'white'}} 
                  />
              </form>
            </div>
                {errors.phone && <div style={{color: "red" , backgroundColor: "#4e3f3f" , padding: 5 , textAlign: "center"}}>{errors.phone}</div>}
            <div className='delivery'>
              <div className='delivery_title'>
              {
                selectedValue === 'delivery' ? <span style={{backgroundColor: "inherit" ,color: 'white'}}>Доставлення</span> : <span style={{backgroundColor: "inherit" , color: 'white'}}>Самовивіз</span>
              }
              </div>
              <div className='delivery_select'>
                  <label className="labl" onClick={() => setServiceMode(3)}>
                      <input type="radio" value="delivery" name="gender" onChange={handleChange} checked={selectedValue === 'delivery'}/> 
                      <div>Доставлення</div>
                  </label>
                  <label className="labl" onClick={() => setServiceMode(2)}>
                      <input type="radio" value="getSelf" name="gender" onChange={handleChange} checked={selectedValue === 'getSelf'}/> 
                      <div>Самовивіз</div>
                  </label>
              </div>
            </div>
              {
                selectedValue === "delivery" ? 
                <div className='order_contants_details'>
                    <Input 
                    startDecorator={<PlaceOutlinedIcon sx={{
                    backgroundColor: '#171010',
                    color: "silver"
                    }}/>} 
                    value={adress ? adress : formData?.address}
                    onClick={() => {
                        setSelectAdress(true)
                    }}
                    readOnly
                    autoComplete="new-password"
                    className="contacts_input" 
                    placeholder="Вкажіть адресу доставлення" 
                    sx={{backgroundColor: "#171010", border: 'none'
                    }} 
                    />
                    <div className='time_choose'>
                      <WatchLaterOutlinedIcon className='time_icon'/>
  
                      <select  className="contacts_input" onChange={(e) => setSelectedTime(e.target.value)}>
  
                      {timeWork.length > 0 ? timeWork.map((e, i) => (
                        <option key={i} value={e}>
                          {e}
                        </option>
                      )) : (
                        <option value="">No available hours</option>
                      )}
  
                      </select>
                    </div>
                </div>
                : []
              }
              
              {
                selectedValue === "getSelf" ? 
                    <div className='order_contants_details'>
                          <Input 
                            startDecorator={<StorefrontIcon sx={{
                            backgroundColor: '#171010',
                            color: "white"
                            }}/>}
                            autoComplete="new-password"
                            disabled
                            value={selectedSpot}
                            className="contacts_input" 
                            // placeholder={selectedSpot}
                            sx={{backgroundColor: "#171010", border: 'none'
                            }} 
                            />
              
                            <div className='time_choose'>
                                <WatchLaterOutlinedIcon className='time_icon'/>
                                <select  className="contacts_input"  onChange={(e) => setSelectedTime(e.target.value)}>
                                {selfGet.length > 0 ? selfGet.map((e, i) => (
                                  <option key={i} value={e}>
                                    {e}
                                  </option>
                                )) : (
                                  <option value="">No available hours</option>
                                )}
                                </select>
                            </div>
                    </div>
                : []
              }
              <div className='delivery'>
                <div className='delivery_title'>
                    <span style={{backgroundColor: "inherit" ,color: 'white'}}>Оплата</span> 
                </div>
                    <Input className='payment_input' disabled placeholder='Готівкою' startDecorator={<CreditCardOutlinedIcon
                    sx={{
                      backgroundColor: 'rgb(159, 156, 156)',
                      color: "white"
                      }}
                    />} sx={{marginBottom: 2}}/>
                    
              </div>
              <div className='order_bottom'>
                <div className='order_bottom_price'>
                    <span>Сумма замовлення: <b>{price + " грн"}</b> </span>
                    {selectedValue === "getSelf"  ? [] : <span>Доставлення: <b>{deliveryPrice}грн</b></span>}
                </div>
                <button type='submit' className='link_bottom' onClick={handleSubmit}>Оформити за <b>{(service_mode == "2" ? price : price  + deliveryPrice )}грн</b></button>
              </div>
           
          </div>
        </div>
      </div>
        <SelectAdress selectAdress={selectAdress} setSelectAdress={setSelectAdress} setAdress={setAdress} setRoom={setRoom} room={room} adress={adress} setOrderActive={setOrderActive}/>
      </>
    )
  }
  
export const OrderModalConnect = connect(state => ({ 
    status: state.promise?.submitOrder?.status || []
}), {

})(OrderModal) 

// handleSubmitOrder(name , phone)
const RowDish = ({ name, price, count, photo, id  , totalPrice}) => {
  const [newCount, setNewCount] = useState(count);
  const [hiddenContent, setHiddenContent] = useState(false)

  const removeFromBasket = () => {
    store.dispatch(actionCartChange(-1, id, name, price, photo));
    setNewCount(newCount - 1);
    if(store.getState().cardReducer[id].count === 0){
      store.dispatch(actionCartRemove(id))
  }
  };

  useEffect(() => {
    if(name == "Тройняшка соусник"){
      setHiddenContent(true)
    }
  }, [name])


  const addToBasket = () => {
    store.dispatch(actionCartAdd(1, id, name, Number(price), photo)); // Pass price as a number
    setNewCount(newCount + 1)
  };

  return (
    <div className='row_dish' key={id}>
    <img src={photo} />
    <div className='row_dish_content'>
      <div className='row_dish_title'>
        <span>{name}</span>
        <span>{totalPrice} грн</span>
      </div>
      {
         hiddenContent ? [] :
      <div className='row_dish_count'>
        <button onClick={removeFromBasket} className={count < 1 ? 'visibleMinus' : 'minus'}>
          <span> - </span>
        </button>
        <span>{newCount}</span>
        <button onClick={addToBasket} className='count_plus'>
          <span> + </span>
        </button>
      </div>
      }
    </div>
  </div>
  );
};

const countPriceDelivery = (selectedSpot, orderPrice, setDeliveryPrice, delivery) => {
  const spot = delivery?.find((spot) => spot.spot_id === selectedSpot?.name);
  if (spot) {
    if (spot.spot_freeDelivery > 0 && orderPrice >= spot.spot_freeDelivery) {
      setDeliveryPrice(0);
    } else {
      setDeliveryPrice(spot.spot_price);
    }
  } else {
    setDeliveryPrice(70);
  }
};

const ModalBasket = ({openBasket , setOpenBasket , count , price , products , productsFranchise  , productsStaroconst, productsMyThaiDnepr , delivery , mainProducts}) => {
  const [orderActive , setOrderActive] = useState(false)

  const [openBasketMobile , setBasketMobile] = useState(false)

  const [isAddressBarVisible, setIsAddressBarVisible] = useState(false);
  const [isSwiping, setIsSwiping] = useState(false);
  const [startY, setStartY] = useState(null);
  const [currentY, setCurrentY] = useState(null);
  const modalRef = useRef(null);
  const contentRef = useRef(null);

  const [comment , setComment] = useState('')

  const [recItemAdded, setRecItemAdded] = useState(true);


  const selectedSpot = useSelector((state) => state.cardReducer?.spot)

  const [deliveryPrice , setDeliveryPrice] = useState(0)

  const [cart, setCart] = useState();
  const [isFixed, setIsFixed] = useState(false);
  const [showComponent, setShowComponent] = useState(true);

  // !!!!!!!!
  const [isMobile, setIsMobile] = useState(true);
  const [souce, setSouce] = useState(0);

  const removeFromBasket = (id,name,price,photo) => {
    store.dispatch(actionCartChange(-1, id, name, price, photo));
    if(store.getState().cardReducer[id].count === 0){
      store.dispatch(actionCartRemove(id))
    }
  };

  const addToBasket = (id,name,price ,photo) => {
    store.dispatch(actionCartAdd(1, id, name, Number(price), photo)); // Pass price as a number
  };

  const handleIncrementSouce = () => {
    setSouce(souce + 1);
    if(selectedSpot?.name === "12" || selectedSpot?.name === "10" || selectedSpot?.name === "8") {
        addToBasket("341","Тройняшка соусник","17","http://joinposter.com/upload/pos_cdb_295869/menu/product_1697301557_96_original.jpeg")
    }else{
        addToBasket("96","Тройняшка соусник","17","http://joinposter.com/upload/pos_cdb_295869/menu/product_1697301557_96_original.jpeg")
    }
  };

  const handleDecrementSouce = () => {
    if (souce > 0) {
      setSouce(souce - 1);
      if(selectedSpot?.name === "12" || selectedSpot?.name === "10" || selectedSpot?.name === "8") {
            removeFromBasket("341","Тройняшка соусник","17","http://joinposter.com/upload/pos_cdb_295869/menu/product_1697301557_96_original.jpeg")
      }else{
            removeFromBasket("96","Тройняшка соусник","17","http://joinposter.com/upload/pos_cdb_295869/menu/product_1697301557_96_original.jpeg")
      }
    }
  };


  useEffect(() => {
    // Функция, которая будет вызвана при изменении размера экрана
    function handleResize() {
      if (window.innerWidth > 768) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }

    // Добавляем слушатель события изменения размера экрана
    window.addEventListener('resize', handleResize);

    // Вызываем обработчик изменения размера экрана при загрузке компонента
    handleResize();

    // Убираем слушатель события при размонтировании компонента
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    // Check if the maximum width of the screen is greater than 800 pixels
    const mediaQuery = window.matchMedia('(max-width: 800px)');

    // Hide the component if the screen is too large
    setShowComponent(mediaQuery.matches);

    // Add a listener to the media query to update the component when the screen size changes
    const handleMediaQueryChange = () => {
      setShowComponent(mediaQuery.matches);
    };

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);
  
  useEffect(() => {
    let copyObject = Object.values(products)
    setCart(copyObject);
    if(count ==="0 товар,"){
      setOpenBasket(false)
    }
}, [products , count]);
  
  useEffect(() => {
    function handleScroll() {
      const scrollY = window.scrollY;
      const button = document.getElementById('fixed-button');
      if (button) {
        if (scrollY > button.offsetTop) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    countPriceDelivery(selectedSpot, price, setDeliveryPrice , delivery);
  }, [selectedSpot, price]);


  useLayoutEffect(() => {
    function handleResize() {
      const isAddressBarVisible = window.visualViewport.height > 700;
      setIsAddressBarVisible(isAddressBarVisible);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    setStartY(touch.clientY);
  };

  const handleTouchMove = (event) => {
    const touch = event.touches[0];
    setCurrentY(touch.clientY);
    if (startY && !isSwiping) {
      setIsSwiping(true);
    }
    if (isSwiping) {
      const contentHeight = contentRef.current.scrollHeight;
      const scrollTop = contentRef.current.scrollTop;
      const swipeDistance = touch.clientY - startY;
      const isScrollingTop = swipeDistance > 0 && scrollTop === 0;
      const isScrollingBottom = swipeDistance < 0 && scrollTop + contentHeight === contentRef.current.offsetHeight;
      if (!isScrollingTop && !isScrollingBottom) {
        event.preventDefault();
      }
    }
  };

  const handleTouchEnd = () => {
    if (isSwiping) {
      const swipeDistance = currentY - startY;
      if (swipeDistance > 50) {
        setOpenBasket(false);
      }
      setIsSwiping(false);
    }
    setStartY(null);
    setCurrentY(null);
  };
  
  const handleSetMobile = () => {
       setBasketMobile(true);
      //  fbq('track', 'InitiateCheckout');
  }
  
    const handleSetMobile1 = () => {
       setOrderActive(true)
      //  fbq('track', 'InitiateCheckout');
  }


  let filterRecomendedProducts = mainProducts?.products?.filter(product =>
    product?.product_name.toLowerCase().includes("агі сяке") ||
    product?.product_name.toLowerCase().includes("чікен-рол") ||
    product?.product_name.toLowerCase().includes("рол без рису з тунцем та лососем") ||
    product?.product_name.toLowerCase().includes("фуджи рол") ||
    product?.product_name.toLowerCase().includes("футомакі з копченою куркою") ||
    product?.product_name.toLowerCase().includes("сяке хот") ||
    product?.product_name.toLowerCase().includes("прайм рол") || 
    product?.product_name.toLowerCase().includes("сніжний краб в ікрі")
  );
  

  let shuffledProducts;
  let randomProduct;
  
  if (filterRecomendedProducts) {
    // Check if filterRecomendedProducts is defined before attempting to shuffle
    shuffledProducts = filterRecomendedProducts.sort(() => Math.random() - 0.5);
  
    if (shuffledProducts.length > 0) {
      // Remove items from shuffledProducts that have the same name as items in cart
      shuffledProducts = shuffledProducts.filter(product =>
        !cart?.some(cartProduct => cartProduct?.name?.toLowerCase() === product?.product_name?.toLowerCase())
      );
  
      // Take the first two items from shuffledProducts
      randomProduct = shuffledProducts.slice(0, 2);
    }
  }

  const changeRecomendedItems = (product_id ,product_name, price , photo1) => {
    addToBasket(product_id ,product_name, removeTrailingZeros(price[2].slice(0, -2)) , photo1 )
    setRecItemAdded(!recItemAdded)
  }

  const removeTrailingZeros = (priceString) => {
    return priceString.replace(/(\d)(?=(\d{3})+$)/g, '$1').replace(/(\.[0-9]*[1-9])0+$|\.0*$/g, '$1');
  }


  if (!openBasket) return null; 
  
  return (
    <>   
        <div className={openBasket ? "modal_basket active" : "modal_basket"}
        style={{ transform: `translateY(${currentY ? currentY - startY : 0}px)` , backgroundColor:   currentY ? "rgba(0,0,0,0)" : [] , borderBottom: isAddressBarVisible ? "50px" : 0}} onClick={() => setOpenBasket(false)}
        >
        <div className='modal_basket_content' ref={modalRef} onClick={(e) => e.stopPropagation()}> 
          <div className='basket_content' ref={contentRef}>
            {showComponent && <div className='close_modal_button' 
                   onTouchStart={handleTouchStart}
                   onTouchMove={handleTouchMove}
                   onTouchEnd={handleTouchEnd}
                >
                    {
                      isSwiping ? <ArrowDropDownIcon/> : <RemoveIcon/>
                    }
                </div>   
            }
            {
              !showComponent ? 
                <div className="close_browser_button" onClick={() => setOpenBasket(false)}>
                  <CloseIcon sx={{color: "white" , fontSize: 40 , backgroundColor: "inherit"}}/>
                </div>
              : []
            }         
            
            <div className={showComponent ? "basket_content_h1mobil" :'basket_content_h1'}>
              {count?.slice(0, -1)}
            </div>
            <div className='basket_content_desc'>
              <span style={ selectedSpot?.name == "11" ? {display: "none"} : {}}>Мінімальне замовлення від <b style={{backgroundColor: "inherit"}}>99 грн</b></span>
                {
                  delivery?.map((spot) =>
                    selectedSpot?.name === spot.spot_id ? (
                      <div className='basket_content_desc1' key={spot.spot_name}>
                        {spot.spot_price > 0 ? (
                          <span>
                            Вартість доставлення <b style={{ backgroundColor: "inherit" }}>
                              від {spot.spot_price} грн</b>
                          </span>
                        ) : null}
                        {spot.spot_freeDelivery > 0 ? (
                        <>
                            {
                                (selectedSpot?.name === "2" || selectedSpot?.name === "3" || selectedSpot?.name === "4" || selectedSpot?.name === "5" || selectedSpot?.name === "6" || selectedSpot?.name === "7" || selectedSpot?.name === "8" || selectedSpot?.name === "9" || selectedSpot?.name === "10" || selectedSpot?.name === "12" || selectedSpot?.name === "13" || selectedSpot?.name === "14") && (
                                    <span>
                                        Безкоштовна доставка при замовленні від
                                        <b style={{ backgroundColor: "inherit" }}> {spot.spot_freeDelivery} грн</b>
                                    </span>
                                )
                            }
                          {
                             selectedSpot?.name == "11" && (
                             <>
                            <span>
                                Безкоштовна доставка від 599грн в радіусі
                                <b style={{ backgroundColor: "inherit" }}> 5 км</b>
                            </span>
                            <span>
                               Правий берег від 1000грн безкоштовна доставка
                                <b style={{ backgroundColor: "inherit" }}>(менше 1000грн - 150грн)</b>
                            </span>
                             </>
                          )
                          }
                        </>
                        ) : null}
                      </div>
                    ) : null
                  )   
                }

            </div>
          </div>
          <div className='basket_content_products'>
              {
                cart?.map((item) => (item.photo ? <RowDish name={item.name} price={item.price} count={item.count} key={item.name} photo={item.photo} id={item.product_id} totalPrice={item.totalPrice}/> : []))
              }
          </div>
          <div
                  style={{
                    width: '90%',
                    padding: "27px",
                    marginBottom: '5px',
                    marginLeft: "5%",
                    marginRight: "10px",
                    marginTop: "5%",
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    position: 'relative',
                    alignItems: 'center',
                    backgroundColor: "silver",
                    color: "black"
                  }}
                >
                    <div style={{ position: "absolute", left: "10px", fontSize: "13px" , wordWrap: "break-word"}}>Додаткові:<br/>Імбір, васабі, соєвий соус</div>
                    <div style={{ position: "absolute", width: "49%", right: "8px", height: "100%", display: "flex" , justifyContent: "center" , alignItems: "center" }}>
                        <button style={{ position: "absolute", right: 80 , height: "30px" ,backgroundColor: "red"  ,  color: "white" , fontSize: "15px" , border: "none" , borderRadius: "5px" , width: "40px"}} onClick={handleDecrementSouce} >-</button>
                          <span style={{ position: "absolute", right: 57 , fontWeight: "bold" }}>{souce}</span>
                        <button style={{ position: "absolute", right: 0 ,  height: "30px" , backgroundColor: "red" , color: "white" ,fontSize: "15px" , border: "none",borderRadius: "5px" ,width: "40px"}} onClick={handleIncrementSouce} >+</button>
                    </div>
          </div>
          <div style={{width: "100%" , textAlign: "center" , paddingBottom: 30 , borderRadius: 20, display: "flex" , flexDirection: "column"}}>
                        <h4 style={{backgroundColor:"red" , borderTopLeftRadius: "10px" , borderTopRightRadius: "10px", color: "white" ,  paddingBottom: 5, paddingTop: 5 , fontSize: 20}}>Рекомендуємо спробувати...</h4>
                        <div style={{backgroundColor: "#c0c0c0" , color: "black" ,borderRadius: 30}}>
                        {
                          Array.isArray(randomProduct) &&
                          randomProduct.map((item) => {
                            const { product_id, photo, product_name, price } = item;

                            let photo1 = `https://joinposter.com${photo}`;

                            return (
                              <div className='row_dish' key={product_id}>
                                <img src={`https://joinposter.com${photo}`} alt={product_name} />
                                <div className='row_dish_content'>
                                  <div className='row_dish_title' style={{ color: "black", alignItems: "flex-start" }}>
                                    <span>{product_name}</span>
                                    <span>{removeTrailingZeros(price[2].slice(0, -2))} грн</span>
                                  </div>
                                  {
                                    (
                                      <div className='row_dish_count'>
                                        <button onClick={() => changeRecomendedItems(product_id, product_name, price, photo1)} className='count_plus'>
                                          <span> + </span>
                                        </button>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                            );
                          })
                        }

                        </div>
          </div>
          <div className='basket_content_textarea'>
            <textarea placeholder='Коментар до замовлення або промокод' onChange={(e) => setComment(e.target.value)}>

            </textarea>
          </div>
          <div className='basket_content_bottom' style={{ position: isFixed ? 'fixed' : 'static', bottom: '20px' }}>
              <div className='basket_content_price'>
                <span> Сума замовлення:</span>
                <span>{price + " грн"}</span>
              </div>
              <div className='basket_content_btn'>
                { isMobile ?
                  <Link  to={{
                    pathname: "/basketMobile",
                  }} state={{
                      price:price,
                      products: cart,
                      productsMyThaiDnepr: productsMyThaiDnepr,
                      productsFranchise: productsFranchise,
                      deliveryPrice:deliveryPrice, 
                      comment:comment,
                      productsStaroconst:productsStaroconst
                  }}
                  style={{
                    backgroundColor: "inherit",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100vw",
                    textDecoration: "none"
                  }}
                  >
                      <button style={{
                        backgroundColor: "red",
                        border: "none",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        fontSize: "18px",
                        borderRadius: "10px",
                        textAlign: "center",
                        color: "white",
                        width: "90%",
                        textDecoration: "none"
                      }}  onClick={() => handleSetMobile()}>
                        Перейти до оформлення
                      </button>
                  </Link>
                  : 
                  <button onClick={() => handleSetMobile1()}>
                      Перейти до оформлення
                  </button>
                }
              {/* <Link to="/basketMobile">
                <button onClick={() => isMobile ? "" : setOrderActive(true)}>
                  Перейти до оформлення
                </button>
              </Link> */}
              </div>
          </div>
        </div>
      </div>
          <OrderModalConnect orderActive={orderActive} setOrderActive={setOrderActive} setOpenBasket={setOpenBasket} price={price} products={cart} productsMyThaiDnepr={productsMyThaiDnepr} productsFranchise={productsFranchise} 
           deliveryPrice={deliveryPrice} comment={comment} productsStaroconst={productsStaroconst}/>
  </>
  )
}

export const ModalBasketConnect = connect(state => ({ 
  products: state.cardReducer, 
  productsFranchise: state.promise?.productsFranchise?.payload?.products,
  productsStaroconst: state.promise?.productsStaroconst?.payload?.products,
  productsMyThaiDnepr: state.promise?.productsMyThaiDnepr?.payload?.products,
  delivery: state.promise?.delivery?.payload?.prices
}), {

})(ModalBasket) 


const Basket = ({basket , mainProducts}) => {
  const [cart, setCart] = useState();

  const [count, setCount] = useState(0);
  const [price, setPrice] = useState(0);
 
  const [openBasket, setOpenBasket] = useState(false);
  const [libraries] = useState(['places']);

  useEffect(() => {
      let copyObject = Object.values(basket)
      setCart(copyObject);
  }, [basket , count]);


  const calculateCartTotal = (cart) => {
    function sumByKey(arr, key) {
      return arr.reduce((acc, obj) => {
        if (obj[key] !== undefined) {
          return acc + obj[key];
        }
        return acc;
      }, 0);
    }
      const totalCount = sumByKey(cart, "count");
      const totalPrice = sumByKey(cart, "totalPrice");

    if (totalCount === 0) {
      setCount("0 товар,");
    } else {
      switch (true) {
        case totalCount === 1:
            setCount("1 товар,");
            setPrice(totalPrice);
           
          break;
        case totalCount > 1 && totalCount < 5:
            setCount(`${totalCount} товари,`);
            setPrice(totalPrice);
            
          break;
        case totalCount >= 5:
            setCount(`${totalCount} товарів,`);
            setPrice(totalPrice);
          
          break;
        default:
          break;
       }
    }
  };
  
  useEffect(() => {
    if (Array.isArray(cart)) {
      calculateCartTotal(cart);
    } else {
    }
  }, [cart]);
  
  useEffect(() => {
    if (openBasket) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [openBasket]);
  
  
  const handleOpening = (event) => {
    event.stopPropagation();
    setOpenBasket(true);
  };


    return (
      <>
      <div className='basket_container'>
          <button className={count !== "0 товар,"? 'btn_basket acti' : "btn_basket"} onClick={handleOpening}>
                  <div className='btn_content'>
                      <ShoppingCartOutlinedIcon style={{backgroundColor: "white", borderRadius: 5 , padding: 4 , color: 'red'}}/>
                      <span>{count}</span>
                      <span>{price + " грн"}</span>
                  </div>
                <div className='order'>
                  Замовити
                  <KeyboardArrowRightIcon style={{color: "white" , backgroundColor: 'inherit'  , marginTop: 2}}/>
                </div>
          </button>
      </div>
      <ModalBasketConnect openBasket={openBasket} setOpenBasket={setOpenBasket} count={count}  price={price} mainProducts={mainProducts}/>
      </>
    )
  }

export const BasketConnect = connect(state => ({ 
    basket: state.cardReducer, 
    mainProducts: state.promise.products?.payload
}), {

})(Basket) 