import React from 'react'

import MenuIcon from '@mui/icons-material/Menu';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import { useState , CSSProperties  } from 'react';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { store } from '../store/store';
import { actionSpotSelect } from '../store/cardReducer';

import ClipLoader from "react-spinners/ClipLoader";
import { Link } from '@mui/material';
import { useNavigate } from 'react-router';
import { useRef } from 'react';
import { useLayoutEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Instagram } from '@mui/icons-material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

import logo from "../images/logo.jpg"

function removePrefix(name) {
        // Remove "hi-thai — " prefix
        name = name?.replace("hi-thai — ", "");
        
        // Remove "my-thai-dnepr — " prefix
        name = name?.replace("my_thai_dnepr", "Дрогобич");

        name = name?.replace("my-thai-kharkov — ", "");

        name = name?.replace("limetest — ", "");
        
        return name;
}

const OurStores = ({ openModal, setOpenModal, spots , limetest , spotsFranchise , my_thai_dnepr }) => {
        useEffect(() => {
          if (openModal) {
            document.body.classList.add("modal-open");
          } else {
            document.body.classList.remove("modal-open");
          }
        }, [openModal]);
      
        if (!openModal) return null;
      
        const markers = [...spots ,limetest[1] , limetest[2],spotsFranchise[2] , spotsFranchise[6]]?.map((spot) => {
                return `markers=color:red%7Clabel:M%7C${spot?.lat},${spot?.lng}`;
              }).join('&');
              
              
              console.log(spots)
      
        return (
          <div
            className={openModal ? "modal_basket active" : "modal_basket"}
            onClick={() => setOpenModal(false)}
          >
            <div className="modal_basket_content" onClick={(e) => e.stopPropagation()}>
                        <div className="close_browser_button" onClick={() => setOpenModal(false)}>
                                <CloseIcon sx={{ color: "white", fontSize: 40, backgroundColor: "inherit" }} />
                        </div>
      
                                <div className="map_our_stores">
                                        <img
                                        src={`https://maps.googleapis.com/maps/api/staticmap?size=800x200&maptype=roadmap&${markers}&key=AIzaSyAXoZSuCo6ZFOpfKyEct58sJnJtc2qla7c`}
                                        alt="Map with markers"
                                        />
                                </div>

                                <div className='ourStores_contact_wrapper'>
                                        <div className='ourStores_contacts'>
                                                <h2>Контакти</h2>
                                                <div className='ourStores_contacts_details'>
                                                        <span>
                                                                <Instagram sx={{color: "red"}}/>
                                                                <div style={{display: "flex" , flexDirection: "column" , marginTop: "25px" , color: "red"}}>
                                                                    <a href='https://www.instagram.com/my.thai.lozova/' style={{textDecoration: "none", color: "red"}}>@my.thai.lozovaya</a>
                                                                    <h4 style={{ color: "white"}}>-тел. <b>0660558522</b></h4>
                                                                </div>
                                                        </span>
                                                          <h4 style={{ marginLeft: "20%"}}>ІПН 3495103338 mythai@gmail.com</h4>
                                                        <span>
                                                                <Instagram  sx={{color: "red"}}/>
                                                                 <div style={{display: "flex" , flexDirection: "column" , marginTop: "25px" , color: "red"}}>
                                                                    <a href='https://www.instagram.com/my.thai.uman/' style={{textDecoration: "none", color: "red"}}>@my.thai.uman</a>
                                                                    <h4 style={{color: "white"}}>-тел. <b>0992308969</b></h4>
                                                                </div>
                                                        </span>
                                                        <h4 style={{ marginLeft: "20%"}}>ІПН 3495103338 mythai@gmail.com</h4>
                                                        
                                                        
                                                </div>
                                        </div>
                                        <div className='ourStores_spots'>
                                                <h2>Заклади</h2>
                                                <div className='ourStores_spots_contacts'>
                                                        {
                                                                [spots[0],spots[1]]?.map((item) => (
                                                                       <div key={item?.spot_id} className="ourStores_spots_contacts_item">
                                                                                <h3>{item?.name ||removePrefix(item?.spot_name)}</h3>
                                                                                <span><PlaceOutlinedIcon sx={{marginRight: "5px"}}/>{item?.address || item?.spot_adress}</span>
                                                                        </div>
                                                                       ))
                                                        }
                                                </div>
                                                
                                        </div>
                                </div>
                        </div>
              </div>
        )
}


export const OurStoresConnect = connect(state => ({ 
        spots: state.promise.franchise?.payload?.franchise["hi-thai"],
        limetest: state.promise.franchise?.payload?.franchise["limetest"],
        my_thai_dnepr: state.promise.franchise?.payload?.franchise["my-thai-dnepr"],
        spotsFranchise: state.promise.spots?.payload?.spots
      }), {
      
      })(OurStores) 


const ToolTip = ({tooltipOpen , setToolTip}) => {
        return(
        <div className={tooltipOpen ? "tooltip act" : "tooltip"}>
                <div className='tooltip_content' onClick={e => e.stopPropagation()}>
                </div>
        </div>
        )
}


const Header = ({spots}) => {
        const [nav , setNav] = useState(false)
        const navigation = useNavigate()

        const [ourStoresModal , setOurStoresModal] = useState(false)
        
        const [selectSpot , setSelectSpot] = useState('')
        const [tooltip , setToolTip] = useState(false)
        const [showComponent, setShowComponent] = useState(true);

        useEffect(() => {
        // Check if the maximum width of the screen is greater than 800 pixels
        const mediaQuery = window.matchMedia('(max-width: 800px)');

        // Hide the component if the screen is too large
        setShowComponent(mediaQuery.matches);

        // Add a listener to the media query to update the component when the screen size changes
        const handleMediaQueryChange = () => {
        setShowComponent(mediaQuery.matches);
        };

        mediaQuery.addEventListener('change', handleMediaQueryChange);

        return () => {
        mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
        }, []);

        const SELECT_VALUE_KEY = "MySelectValue";
        
        const filteredPayload = spots?.filter(item => item.spot_id !== "7");

        const handleOnChange = (e) => {
                const selectElement = document.getElementById("select");
                const selectedOption = selectElement.options[selectElement.selectedIndex];
                const address = selectedOption.getAttribute("adress");
                console.log(address)
                setSelectSpot(e.target.value)
                localStorage.setItem(SELECT_VALUE_KEY , JSON.stringify(e.target.value))
                localStorage.setItem("restaurant" , e.target.value)
                localStorage.setItem("adress" , address)
                store.dispatch(actionSpotSelect(e.target.value , address))
                setToolTip(false)
        }

        useEffect(() => {
                const lastSelected = JSON.parse(
                  localStorage.getItem(SELECT_VALUE_KEY) ?? "[]"
                );
                setSelectSpot(lastSelected);
        }, []);

        
        function check () {
               setToolTip(true)
               const popup = document.getElementById('myPopup')
               popup.classList.toggle("show");
        }

        useEffect(() => {
                if(!localStorage.restaurant){
                        setToolTip(true)
                        localStorage.setItem(SELECT_VALUE_KEY , JSON.stringify("..."))
                        check()
                }
        }, [localStorage])
        

        useEffect(() => {
                if(tooltip){
                        const el = document.querySelector("html , body");
                        el.classList.toggle("startScroll")
                }
        }, [tooltip])

        const handleNavigate = (e) => {
                if(e == "payment"){
                        navigation("/payment")
                }
                if(e == "contacts"){
                        navigation("/contacts")
                }
                if(e == "discount"){
                        navigation("/discount")
                }
                 if(e == "our_restaurants"){
                        navigation("/our_restaurants")
                }
                if(e == "franchise"){
                        window.location.href = "https://mythai.webflow.io/?fbclid=PAAabgEpg66b49nm-IHqUt8OvPgeFVq3tweKGjDu_RoZz5Eodrkv76GkEo9OY"
                }
                if(e == "refund"){
                       navigation("/refund")
                }
        }
        
        return (
        <>
        <div className='header'>
                        <nav className='header_content'>
                                <a className='logo'>
                                <img src={logo}/>
                                </a>
                                <div className='header_menu'> 
                                        <ul className={nav ? [ 'menu', 'active'].join(' ') : ['menu']}>
                                                <li onClick={() => handleNavigate("payment")}>Оплата та доставка</li>
                                                <li onClick={() => handleNavigate("contacts")}>Контакти</li>
                                                <li onClick={() => handleNavigate("discount")}>Акції</li>
                                                <li onClick={() => handleNavigate("refund")}>Правила повернення коштів</li>
                                                 {showComponent && <li onClick={() => handleNavigate("our_restaurants")}>Наша мережа</li>}
                                                 <li onClick={() => handleNavigate("franchise")}>Франшиза MyThai</li>
                                        </ul>
                                        <div className="popup">
                                                <span className={ tooltip ? "popuptext": "myPopupON"} id="myPopup">Оберіть заклад</span>
                                                        <select className='citySelect' id="select"  value={selectSpot} onChange={handleOnChange} style={{backgroundColor: "#9e1500" , borderRadius: "10px"}}>
                                                                <option disabled>...</option>
                                                        {
                                                                filteredPayload?.map((item) => (
                                                                <option key={item.spot_id} value={item.spot_id} adress={item.spot_adress} className="option">{removePrefix(item.spot_name)}</option>))
                                                        }
                                                        </select>
                                        </div>
                                        <ul style={{color: "red"}} className='contacts'>
                                                <li onClick={() => setOurStoresModal(true)}>Контакти</li>
                                        </ul>
                                </div>
                        
                                <div className='mobile_btn' onClick={() => setNav(!nav)}>
                                        {nav ? <RestaurantMenuIcon style={{color: 'red' , fontSize: 40}}/> : 
                                        <MenuIcon style={{color: 'red' , fontSize: 40}}/>
                                        
                                        }
                                </div>
                        </nav>
                </div>
                <ToolTip tooltipOpen={tooltip} setToolTip={setToolTip}/>
                <OurStoresConnect openModal={ourStoresModal} setOpenModal={setOurStoresModal}/>
        </>
        )
}

export const HeaderConnect = connect(state => ({ 
        spots: state.promise.spots?.payload?.spots
      }), {
      
      })(Header) 




// const SelectOption = (props) => {
//          const [selectedOption, setSelectedOption] = useState(null); 
//          const [localStorageOption, setLocalStorageOption] = useLocalStorage( "selectedOption" );

//         React.useEffect(() => { 
//                 if (localStorageOption) { 
//                         setSelectedOption(localStorageOption); 
//                 } 
//         }, [localStorageOption]);


// const handleChange = (e) => { 
//         setSelectedOption(e.target.value);
//         setLocalStorageOption(e.target.value); 
// };

// return ( 
// {option.label}
//  ); };

// export default SelectOption;

// FREE
