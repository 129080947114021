// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  min-height: 100%;
  /* background-image: linear-gradient(to right top, #8b001e, #69091f, #480e1c, #270d14, #000000); */
  /* background-image: linear-gradient(to left bottom, #051937, #35214e, #662356, #94264f, #b53a3a); */
    display: block;
}

.Appnone {
    display: none;
}


.cont_svg {
      min-width: 110% !important;
      height: 100vh !important;
      background-color: #e8e6e6;
      z-index: 499;
      position: relative;
}
.cont_svg > p {
    display: block;
    position: absolute;
    color: black;
    z-index: 950;
    font-size: 50px;
     top: 60%;
     padding-left: 5%;
}
.svg {
     width: 400px !important;
     height: 300px !important;
     /*left: 200px;*/
     top: 20%;
    margin-left: 8%;
    /*min-height: 20% !important;*/
    /*left: -350px;*/
    position: absolute;
     background: #e8e6e6 !important;
     z-index: 498;
     background-repeat: repeat, repeat;
}

/*.svg {*/
/*  min-width: 100%; */
/*  min-height: 100%; */
/*  left: 0; */
/*  top: 0;*/
/*  position: absolute;*/
/*  overflow: hidden;*/
/*}*/
#light {
  background-color: #171010;
}

#dark {
  background-color: dimgray;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kGAAkG;EAClG,oGAAoG;IAClG,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;;AAGA;MACM,0BAA0B;MAC1B,wBAAwB;MACxB,yBAAyB;MACzB,YAAY;MACZ,kBAAkB;AACxB;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,eAAe;KACd,QAAQ;KACR,gBAAgB;AACrB;AACA;KACK,uBAAuB;KACvB,wBAAwB;KACxB,eAAe;KACf,QAAQ;IACT,eAAe;IACf,8BAA8B;IAC9B,gBAAgB;IAChB,kBAAkB;KACjB,8BAA8B;KAC9B,YAAY;KACZ,iCAAiC;AACtC;;AAEA,SAAS;AACT,sBAAsB;AACtB,uBAAuB;AACvB,cAAc;AACd,YAAY;AACZ,wBAAwB;AACxB,sBAAsB;AACtB,IAAI;AACJ;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".App {\n  min-height: 100%;\n  /* background-image: linear-gradient(to right top, #8b001e, #69091f, #480e1c, #270d14, #000000); */\n  /* background-image: linear-gradient(to left bottom, #051937, #35214e, #662356, #94264f, #b53a3a); */\n    display: block;\n}\n\n.Appnone {\n    display: none;\n}\n\n\n.cont_svg {\n      min-width: 110% !important;\n      height: 100vh !important;\n      background-color: #e8e6e6;\n      z-index: 499;\n      position: relative;\n}\n.cont_svg > p {\n    display: block;\n    position: absolute;\n    color: black;\n    z-index: 950;\n    font-size: 50px;\n     top: 60%;\n     padding-left: 5%;\n}\n.svg {\n     width: 400px !important;\n     height: 300px !important;\n     /*left: 200px;*/\n     top: 20%;\n    margin-left: 8%;\n    /*min-height: 20% !important;*/\n    /*left: -350px;*/\n    position: absolute;\n     background: #e8e6e6 !important;\n     z-index: 498;\n     background-repeat: repeat, repeat;\n}\n\n/*.svg {*/\n/*  min-width: 100%; */\n/*  min-height: 100%; */\n/*  left: 0; */\n/*  top: 0;*/\n/*  position: absolute;*/\n/*  overflow: hidden;*/\n/*}*/\n#light {\n  background-color: #171010;\n}\n\n#dark {\n  background-color: dimgray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
