import React from 'react'
import { useNavigate } from 'react-router'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const RefundPolicy = () => {
        const navigation = useNavigate()
  return (
    <div className='payment'>
        <div className='header'>
            <div className='header_content'>
                <div onClick={() => navigation("/")} className="payment_header_content">
                    <KeyboardArrowLeftIcon className='order_content_top_arrow' sx={{color:"red"}}/> 
                    <span style={{color: "red"}}>Назад до меню</span>
                </div>
            </div>
        </div>
        <div className='payment_body'>
            <div className='payment_body_content'>
                <h2>ПОЛІТИКА ПОВЕРНЕННЯ КОШТІВ MYTHAI</h2>
                <h4 style={{ paddingBottom: '4%'}}>
                MYTHAI повідомляє, що законодавством України, зокрема, Законом України «Про захист прав споживачів», не передбачено можливості повернення продовольчої продукції належної якості. <br/> </h4>
                <h4 style={{ paddingBottom: '4%'}}>Так, повернення грошових коштів за продукцію належної якості MYTHAI не здійснюється.<br/></h4>
                <h4 style={{ paddingBottom: '4%'}}>Повернення грошових коштів можливе виключно у наступних випадках: <br/></h4>
                <p>1. Продукція, придбана у MYTHAI , неналежної якості;<br/></p>
                <p style={{paddingBottom: "4%"}}>2. Споживачу/Покупцю доставлено продукцію, яка якісними чи кількісними характеристиками не відповідає його замовленню. <br/></p>
                <h4 style={{ paddingBottom: '4%'}}>У виявлення Покупцем/Споживачем, що йому передана продукція неналежної якості, він має право до закінчення терміну придатності повернути продукцію неналежної якості MYTHAI  і вимагати повернення сплаченої грошової суми, або вимагати заміни продукції неналежної якості. При цьому вартість продукції повертається клієнту протягом 3-х днів з моменту отримання заяви Покупця/Споживача про це. </h4>
                
                <h4 style={{ paddingBottom: '4%'}}>У разі виявлення Споживачем/Покупцем розбіжностей між його замовленням та отриманою продукцією, Споживач/Покупець має право прийняти дану продукцію або відмовитися від неї і вимагати заміни на продукцію, передбачену замовленням, або повернення грошових коштів за фактично не одержану продукцію. Заміна продукції, що не відповідає замовленню за асортиментом, здійснюється шляхом оформлення нового замовлення. </h4>
            </div>
        </div>
    </div>
  )
}

export default RefundPolicy